<template>
    <section id="one">
        <Header />
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h1 class="h1 mb-3 w-100">
                        <span class="light_blue" v-html="$t('aquiring.section-1.title')"></span>
                    </h1>
                    <div v-html="$t('aquiring.section-1.description')"></div>
                </div>
                <div class="col-12 col-md-6 d-none d-md-block">
                    <div class="d-flex flex-wrap gap_25 ms-auto justify-content-end align-items-center">
                        <img src="/static/img/aquiring-page/section-1/1.svg" alt="">
                        <img src="/static/img/aquiring-page/section-1/2.svg" alt="">
                        <img src="/static/img/aquiring-page/section-1/3.svg" alt="">
                        <img src="/static/img/aquiring-page/section-1/4.svg" alt="">
                        <img src="/static/img/aquiring-page/section-1/6.svg" alt="">
                        <img src="/static/img/aquiring-page/section-1/5.svg" alt="">
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import Header from '@/components/Header.vue'
    export default {
        components: {
            Header,
        }
    }
</script>

<style scoped>
    section {
        padding: 0 0 200px;
        background-color: #000;
        background: no-repeat url(/public/static/img/crypto-page/bg.png), #000;
        background-position: bottom 86% center;
        background-size: cover;
        z-index: 1;
    }

    .gap_25 {
        gap: 25px;
    }

    .h1 {
        margin-bottom: 15px;
    }

    .h2 {
        margin-bottom: 86px;
    }

    .myButton {
        margin-bottom: 52px;
    }

    .myCard {
        height: 135px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        padding: 15px 0 0 20px;
    }

    .myCard img {
        margin-left: auto;
    }

    .myGap {
        row-gap: 30px;
    }

    .undertitle {
        margin-bottom: 20px;
        color: #FFF;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .crypto_one_list {
        list-style-type: disc;
        margin-bottom: 143px;
        color: #FFF;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .crypto_one_list li {
        list-style-type: disc;
        list-style-position: inside;

    }

    @media (max-width: 768px) {
        .h1 {
            font-family: "Days One", sans-serif;
            font-size: 40px;
            line-height: 102%;
            /* 30.6px */
        }

        section {
            background: no-repeat url(http://localhost:8080/img/Background112.2c51dca1.png), #000;
            background-position: top -437px center;
            background-size: 100%;
            padding: 0 0 327px !important;

        }

        #first_screen {
            padding: 0 0 327px;
        }
    }

    @media (max-width: 500px) {
        .h1 {
            font-family: "Days One", sans-serif;
            font-size: 30px;
        }
        section {
            background-position: top center;
            background-size: 100%;
        }

        .undertitle {
            font-size: 18px;
        }
    }
</style>