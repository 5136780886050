<template>
    <section>
        <div class="brs">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <img src="/static/img/main-page/s4-1.svg">
                    </div>
                    <div class="col-12 col-lg-5 ms-auto d-flex flex-column justify-content-center">
                        <h2 class="h2" v-html="$t('home.section4.title')"></h2>
                        <p class="subtitle">
                            {{ $t('home.section4.description') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    background: #363957;
    padding: 0 !important;
    border-radius: 0 !important;
}

.brs {
    padding: 48px 0 68px;
    background-color: #1E1D1E;
    border-radius: 60px 60px 0 0;
}

.h2 {
    margin-bottom: 15px;
}

.subtitle {
    margin-bottom: 41px;
}

img {
    width: 100%;
    max-width: fit-content;
}

@media(max-width: 768px) {
    .brs {
        padding: 20px 0 60px;
        border-radius: 20px 20px 0 0;

    }
    img {
        margin-bottom: 20px;
    }
}

@media(max-width: 500px) {

    .subtitle {
        margin-bottom: 0;
    }
}
</style>