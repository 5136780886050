  <template>

        <Section1/>
        <Section2/>
        <Benefits/>
        <ForWho/>
        <Functions/>
        <APIdoc/>
        <Withdrawal/>
        <Section8/>
        <EasyConnect/>
        <Other/>
        <Footer/>
  
  </template>
  
  <script>

    import Section1 from "@/components/alternative-page/Section1.vue";
    import Section2 from "@/components/alternative-page/Section2.vue";
    import Benefits from "@/components/alternative-page/Benefits.vue";
    import ForWho from "@/components/alternative-page/ForWho.vue";
    import Functions from "@/components/alternative-page/Functions.vue";
    import APIdoc from "@/components/alternative-page/APIdoc.vue";
    import Withdrawal from "@/components/alternative-page/Withdrawal .vue";
    import Section8 from "@/components/alternative-page/Telegram.vue";
    import EasyConnect from "@/components/EasyConnect.vue";
    import Other from "@/components/Other.vue";
    import Footer from "@/components/Footer.vue";
  
  export default {
    name: 'AlternativeView',
      components: {
        Section1,
        Section2,
        Benefits,
        ForWho,
        Functions,
        APIdoc,
        Withdrawal,
        Section8,
        EasyConnect,
        Other,
        Footer,
      },
  };

  </script>
  
<style scoped>
  #other_section, #benefits_section, #for_who_section, #APIdoc, #telegram_section {
    border-radius: 0;
  }
  @media(max-width: 768px) {
    section {
      border-radius: 20px 20px 0 0;
      padding: 50px 0;
    }
    .myBrs {
      border-radius: 20px 20px 0 0 !important;
      padding: 50px 0;
    }
  }
</style>
  