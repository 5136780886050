<template>
  <section class="cards position-relative">
    <div class="container">
      <div class="row myGap">
        <div class="col-12 col-md-6 ">
          <div class="myCard">
            <img src="/static/img/engine-page/section-2/500.svg">
            <span v-html="$t('engine.section-2.cards.card-1-title')"></span>
          </div>
        </div>
        <div class="col-12 col-md-6 ">
          <div class="myCard">
            <img src="/static/img/engine-page/section-2/value.svg">
            <span v-html="$t('engine.section-2.cards.card-2-title')"></span>
          </div>
        </div>
        <div class="col-12 col-md-6 ">
          <div class="myCard">
            <img src="/static/img/engine-page/section-2/geograph.svg">
            <span v-html="$t('engine.section-2.cards.card-3-title')"></span>
          </div>
        </div>
        <div class="col-12 col-md-6 ">
          <div class="myCard">
            <img src="/static/img/engine-page/section-2/traff.svg">
            <span v-html="$t('engine.section-2.cards.card-4-title')"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  section {
    padding: 50px 0;
    background-color: #fff;
    color: #fff;
    height: fit-content;
    border-radius: 60px 60px 0 0;
    margin-top: -50px;
  }

  .myCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 210px;
    font-size: 20px;
    padding: 35px 25px;
  }

  .myCard img {
    max-width: fit-content;
  }

  .myGap {
    row-gap: 24px;
    margin-top: -130px;
  }

  @media (max-width:768px) {
    section {
      border-radius: 20px 20px 0 0;
      padding: 50px 0 80px;
    }
  }

  @media (max-width:500px) {
    .myCard {
      font-size: 18px;
      height: 156px;
      padding: 18px 17px;
    }
    .myCard span br {
      display: none;
    }
    .cards {
      padding-bottom: 50px;
    }
  }
</style>