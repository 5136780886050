<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-7">
                    <h2 class="h2" v-html="$t('cards.section-9.title')"></h2>
                    <div class="text">
                        <p v-html="$t('cards.section-9.description')"></p>
                    </div>
                </div>
                <div class="cards">
                    <div class="row myGap">
                        <div v-for="(item, index) in imgSrc" :key="index" class="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div class="myCard">
                                <img :src="item">
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div class="myCard special">
                                <span v-html="$t('cards.section-9.link')"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            imgSrc: [
                '/static/img/cards-page/section-9/Adidas_Logo.svg',
                '/static/img/cards-page/section-9/Amazon_logo.svg',
                '/static/img/cards-page/section-9/App_Store_(iOS).svg',
                '/static/img/cards-page/section-9/Blizzard_Entertainment_Logo.svg',
                '/static/img/cards-page/section-9/EA_Play_logo.svg',
                '/static/img/cards-page/section-9/Google_Play_2022_logo.svg',
                '/static/img/cards-page/section-9/playstation-store-logo-brandlogos.net_92m7idlaq.svg',
                '/static/img/cards-page/section-9/Spotify_logo_with_text.svg',
                '/static/img/cards-page/section-9/Steam_icon_logo.svg',
                '/static/img/cards-page/section-9/Twitch_logo.svg',
                '/static/img/cards-page/section-9/Xbox_app_logo.svg',
            ]
        }
    },
}
</script>

<style scoped>
section {
    color: #161616;
    background: #fff;
    border-radius: 0 !important;
    padding: 50px 0 133px;
}

.h2 {
    font-family: "Days One", sans-serif;
    font-size: 40px;
    margin-bottom: 23px;
}

.text {
    margin-bottom: 55px;
}

.myCard {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    height: 104px;
}

.myCard img {
    max-width: fit-content;
}

.myCard span {
    margin-top: auto;
}

.myCard.special {
    color: #FFF;
    font-family: "Helvetica Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 17px;
    background-color: #6D7AF2;
}

.myGap {
    row-gap: 20px;
    align-content: stretch;
}

@media (max-width: 768px) {
    .section {
        padding: 50px 0;
        border-radius: 20px 20px 0 0;
    }
}

@media (max-width: 500px) {
    .h2 {
        font-size: 25px;
        line-height: 87.2%;
        margin-bottom: 10px;
    }

    .text {
        font-size: 16px;
        margin-bottom: 30px;
    }

}
</style>