<template>
  <!-- <swiper
    :direction="'vertical'"
    :mousewheel="true"
    :spaceBetween="0"
    :slides-per-view="1"
    :effect="'creative'"
    :creativeEffect="{
      prev: {
        shadow: true,
        translate: ['-100%', 0],
      },
      next: {
        translate: ['100%', 0],
      },
    }"
    :modules="modules"
    class="mySwiper"
  > -->
    <!-- <swiper-slide id="slide-1"><Section1/></swiper-slide>
    <swiper-slide id="slide-2"><Section2/></swiper-slide>
    <swiper-slide id="slide-3"><Section3/></swiper-slide>
    <swiper-slide id="slide-4"><Section4/></swiper-slide>
    <swiper-slide id="slide-5"><Section5/></swiper-slide>
    <swiper-slide id="slide-6"><Section6/></swiper-slide>
    <swiper-slide id="slide-7"><EasyConnect/><Footer/></swiper-slide>
</swiper> -->

  <Section1/>
  <Section2/>
  <Section3/>
  <Section4/>
  <Section5/>
  <Section6/>
  <EasyConnect/>
  <Footer/>
</template>

<script>
import Section1 from "@/components/main-page/Section1.vue";
import Section2 from "@/components/main-page/Section2.vue";
import Section3 from "@/components/main-page/Section3.vue";
import Section4 from "@/components/main-page/Section4.vue";
import Section5 from "@/components/main-page/Section5.vue";
import Section6 from "@/components/main-page/Section6.vue";
import EasyConnect from "@/components/EasyConnect.vue";
import Footer from "@/components/Footer.vue";

// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { Mousewheel, EffectCreative } from 'swiper/modules';

// Import Swiper styles
// import 'swiper/css';

export default {
  name: 'HomeView',
    components: {
      Section1,
      Section2,
      Section3,
      Section4,
      Section5,
      Section6,
      EasyConnect,
      Footer
    },
  };
</script>

<style scoped>

.swiper {
  width: 100%;
  height: 100%;
}

#easy_connect_section {
  background: #1E1D1E !important;
}
</style>
