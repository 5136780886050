<template>
  <section id="what_you_take" class="p-0">
    <img src="/static/img/engine-page/section-3/what_take_pos.svg" class="pattern d-none d-sm-block">
        <div class="myBrs">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <h2 class="h2">
                            {{ $t('engine.section-3.title') }}
                        </h2>
                        <img src="/static/img/engine-page/what_you_take.svg" class="mw-100 d-block d-sm-none mb_30">
                        <h5 class="h5">{{ $t('engine.section-3.subtitle') }}</h5>
                        <div class="text">
                            <p>{{ $t('engine.section-3.description') }}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="d-flex justify-content-center align-items-end w-100 h-100">
                            <img src="/static/img/engine-page/what_you_take.svg" width="247" height="108" class="mw-100 d-none d-sm-block">
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    section {
        color: #fff;
        background-color:#fff;
        position: relative;
        border-radius: 0;
    }

    .h2 {
        margin-bottom: 54px;
        font-family: "Days One", sans-serif;
    }

    .h5 {
        font-family: "Days One", sans-serif;
        font-size: 24px;
        margin-bottom: 23px;
        font-weight: bold;
    }

    .myBrs {
        padding: 109px 0 40px;
        border-radius: 60px 60px 0 0;
        background: linear-gradient(92deg, #000 3.21%, #201F20 99.75%);
    }

    .pattern {
        position: absolute;
        top: 40px;
        right: 0;
    }

    @media(max-width: 768px) {
        .myBrs {
          border-radius: 20px 20px 0 0 !important;
          padding: 50px 0;
        }
    }

    @media (max-width: 500px) {
        .h2 {
            margin-bottom: 30px;
            font-size: 25px;
            font-weight: 400;
            line-height: 87.2%; /* 21.8px */
        }
        .h5 {
            font-size: 18px;
            line-height: 89.743%;
        }
    }

</style>