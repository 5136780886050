<template>
    <section class="p-0">
        <div class="myBrs">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="icons w-100 gap-4 d-flex d-sm-none mb_30 flex-wrap">
                            <img src="/static/img/cards-page/section-2,3,4,5,6/4.svg">
                            <img src="/static/img/cards-page/section-2,3,4,5,6/5.svg">
                            <img src="/static/img/cards-page/section-2,3,4,5,6/1.svg">
                        </div>
                        <h5 class="h5" v-html="$t('cards.section-4.title')"></h5>
                        <div class="text">
                            <p class="m-0" v-html="$t('cards.section-4.description')"></p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="d-flex justify-content-center w-100 h-100">
                            <div class="icons w-100 gap-2 justify-content-between align-items-center d-none d-sm-flex flex-wrap">
                                <img src="/static/img/cards-page/section-2,3,4,5,6/4.svg">
                                <img src="/static/img/cards-page/section-2,3,4,5,6/5.svg">
                                <img src="/static/img/cards-page/section-2,3,4,5,6/1.svg">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    section {
        color: #fff;
        background: linear-gradient(92deg, #000 3.21%, #201F20 99.75%);
        border-radius: 0 !important;
    }

    .h5 {
        font-family: "Days One", sans-serif;
        font-size: 24px;
        margin-bottom: 23px;
        font-weight: bold;
    }

    .myBrs {
        padding: 40px 0;
        border-radius: 60px 60px 0 0;
        background-color: #363957;
    }

    .text {
        max-width: 369px;
    }

    .icons img {
        max-width: fit-content;
    }

    @media(max-width: 768px) {
        .myBrs {
            border-radius: 20px 20px 0 0 !important;
            padding: 50px 0;
        }
        .icons img {
            height: 40px;
        }
    }

    @media (max-width: 500px) {
        .h5 {
            font-size: 18px;
            line-height: 89.743%;
        }

    }
</style>