<template>
  <section id="accordeon">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <img :src="currentImage" class="rounded myImg d-none d-sm-block">
        </div>
        <div class="col-12 col-md-6">
          <div class="accordion" id="accordionExample">
            <div
              class="accordion-item shadow-none"
              v-for="(item, index) in items"
              :key="index"
            >
              <h2 class="accordion-header rounded-top">
                <button
                  class="accordion-button collapsed shadow-none"
                  type="button"
                  :data-bs-toggle="'collapse'"
                  :data-bs-target="'#collapse' + index"
                  aria-expanded="false"
                  :aria-controls="'collapse' + index"
                  @click="toggleAccordion(index)"
                >
                  {{ item.title }}
                </button>
              </h2>
              <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ item.description }}
                  <img
                    :src="item.mobImage"
                    class="rounded mt-3 d-block d-sm-none mx-auto mw-100"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      activeIndex: null,
      images: [
        '/static/img/aquiring-page/section-1/big-1.svg', // Default image
        '/static/img/aquiring-page/section-1/big-1.svg', // Image for the first item
        '/static/img/aquiring-page/section-1/big-2.svg',
        '/static/img/aquiring-page/section-1/big-3.svg',
        '/static/img/aquiring-page/section-1/big-4.svg',
        '/static/img/aquiring-page/section-1/big-5.svg'
      ]
    };
  },
  computed: {
    currentImage() {
      return this.activeIndex !== null ? this.images[this.activeIndex + 1] : this.images[0];
    }
  },
  watch: {
    '$i18n.locale': 'updateItems' // Watch for changes in locale and call updateItems
  },
  created() {
    this.updateItems(); // Initialize items on component creation
  },
  methods: {
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    updateItems() {
      this.items = [
        {
          title: this.$t('aquiring.section-2.accordion.item-1.title'),
          description: this.$t('aquiring.section-2.accordion.item-1.description'),
          mobImage: '/static/img/aquiring-page/section-2/mob-1.svg'
        },
        {
          title: this.$t('aquiring.section-2.accordion.item-2.title'),
          description: this.$t('aquiring.section-2.accordion.item-2.description'),
          mobImage: '/static/img/aquiring-page/section-2/mob-2.svg'
        },
        {
          title: this.$t('aquiring.section-2.accordion.item-3.title'),
          description: this.$t('aquiring.section-2.accordion.item-3.description'),
          mobImage: '/static/img/aquiring-page/section-2/mob-3.svg'
        },
        {
          title: this.$t('aquiring.section-2.accordion.item-4.title'),
          description: this.$t('aquiring.section-2.accordion.item-4.description'),
          mobImage: '/static/img/aquiring-page/section-2/mob-4.svg'
        },
        {
          title: this.$t('aquiring.section-2.accordion.item-5.title'),
          description: this.$t('aquiring.section-2.accordion.item-5.description'),
          mobImage: '/static/img/aquiring-page/section-2/mob-5.svg'
        }
      ];
    }
  }
};
</script>


<style scoped>
section {
  padding: 135px 0 175px;
  background-color: #fff;
  color: #fff;
  margin-top: -70px;
  height: fit-content;
  border-radius: 60px 60px 0 0;
}

.accordion-item {
  margin-bottom: 20px;
  border: 0;
}

.myImg {
  max-width: 100%;
}

.accordion-button {
  background-color: #363957;
  border-radius: 20px 20px 0 0 !important;
  color: #FFF;
  font-family: "Days One", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordion-button.collapsed {
  border-radius: 20px !important;
}

.accordion-collapse {
  background-color: #F2F2F2;
  border-radius: 20px;
  position: relative;
}

.accordion-button::after {
  background-image: url(/public/static/img/aquiring-page/section-2/arrow.svg) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(/public/static/img/aquiring-page/section-2/arrow.svg) !important;
  transform: rotate(180deg)
}

#accordionExample {
  padding-top: 12px;
}

.accordion-item .accordion-collapse {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 768px) {
  .myImg {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 30px;
    display: block;
  }
  section {
    border-radius: 20px 20px 0 0;
    padding: 50px 0 50px;
  }
}

@media (max-width: 500px) {
  .accordion-button {
    font-size: 18px;
  }
  .accordion-body {
    font-size: 16px;
  }
}
</style>
