<template>
  <section id="benefits_section">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <h2 class="h2">
                        {{ $t('alternative.benefits.title') }}
                    </h2>
                </div>
            </div>
                
            <div class="grid-container">
                <div class="grid-item card-dark">
                    <div class="block">
                        <img src="/static/img/alternative-page/section-3/1.svg" class="card-img">
                    </div>
                    <h6 v-html="$t('alternative.benefits.benefit-1')"></h6>
                </div>
                <div class="grid-item card-dark">
                    <div class="block">
                        <img src="/static/img/alternative-page/section-3/2.svg" class="card-img">
                    </div>
                    <h6 v-html="$t('alternative.benefits.benefit-2')"></h6>
                </div>
                <div class="grid-item card-dark">
                    <div class="block">
                        <img src="/static/img/alternative-page/section-3/3.svg" class="card-img">
                    </div>
                    <h6 v-html="$t('alternative.benefits.benefit-3')"></h6>
                </div>
                <div class="grid-item card-dark">
                    <div class="block">
                        <img src="/static/img/alternative-page/section-3/6.png" class="card-img">
                    </div>
                    <h6 v-html="$t('alternative.benefits.benefit-4')"></h6>
                </div>
                <div class="grid-item card-dark">
                    <div class="block">
                        <img src="/static/img/alternative-page/section-3/5.png" class="card-img">
                    </div>
                    <h6 v-html="$t('alternative.benefits.benefit-5')"></h6>
                </div>
                <div class="grid-item card-dark">
                    <img src="/static/img/alternative-page/section-3/7.svg" class="card-img">
                    <h6 v-html="$t('alternative.benefits.benefit-6')"></h6>
                </div>
                
            </div>
        </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    section {
        padding: 75px 0 120px;
        background-color: #fff;
        color: #161616;
    }

    .block {
        border-radius: 15px;
        background: #363957;
        width: 65px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
    }

    .grid-item:last-child .card-img {
        width: 64px;
        height: 64px;
    }

    .h2 {
        margin-bottom: 63px;
    }

    .list_item {
        display: flex;
        align-items: center;
        gap: 11px;
        max-width: 348px;
    }
    
    .list_items {
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: #1E1D1E;
        font-size: 25px;
        font-weight: 500;
        line-height: 87.2%; /* 21.8px */
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Три колонки в первом ряду */
        grid-template-rows: auto auto; /* Два ряда */
        grid-gap: 30px; /* Отступы между блоками */
        align-items: stretch;
    }
    
    .card-dark {
        border-radius: 20px;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .card-dark h6 {
        max-width: 235px;
        font-family: "Helvetica Neue";
        font-size: 24px;
        font-weight: 500;
        color: #161616;
    }

    @media (max-width: 768px) {
        .grid-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 500px) {
        .h2 {
            color: #161616;
            font-family: "Days One", sans-serif;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 87.2%; /* 21.8px */
        }
        .card-dark h6 {
            font-size: 16px;
            font-weight: bold;
        }
    }

</style>