<template>
    <section id="one">
        <img src="/static/img/newbg.jpg" class="bg">
        <img src="/static/img/newBgMob.jpg" class="bg-mobile">
        <Header />
        <div class="container">
            <div class="row position-relative z-2">
                <div class="col-12 col-lg-7">
                    <h1 class="h1 mb-3 w-100" v-html="$t('engine.section-1.title')"></h1>
                    <p class="undertitle mb_30">
                        {{ $t('engine.section-1.description') }}
                    </p>
                    <div class="d-flex flex-column flex-sm-row gap-3 buttons">
                        <a target="_blank" :href="$t('engine.section-1.button-1-link')" class="myButton transparent_btn m-0">
                            {{ $t('engine.section-1.button-1') }}
                        </a>
                        <a data-bs-toggle="modal" data-bs-target="#supportModal" href="#" class="myButton m-0">
                            {{ $t('engine.section-1.button-2') }}
                        </a>
                    </div>
                </div>
               
            </div>

        </div>
    </section>
</template>

<script>
    import Header from '@/components/Header.vue'
    export default {
        components: {
            Header,
        }
    }
</script>

<style scoped>
    section {
        padding: 0 0 221px;
        background: #000;
        position: relative;
        overflow: hidden;
    }

    
    .bg {
        position: absolute;
        right: 0;
        bottom: -150px;
    }
    .bg-mobile {
        display: none;
    }

    .transparent_btn {
        border: 1px solid #6D7AF2;
        background-color: #000;
    }

    .gap_25 {
        gap: 25px;
    }

    .h1 {
        margin-bottom: 15px;
    }

    .h2 {
        margin-bottom: 86px;
    }

    .myButton {
        margin-bottom: 52px;
    }

    .myCard {
        height: 135px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        padding: 15px 0 0 20px;
    }

    .myCard img {
        margin-left: auto;
    }

    .myGap {
        row-gap: 30px;
    }

    .undertitle {
        color: #FFF;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .crypto_one_list {
        list-style-type: disc;
        margin-bottom: 143px;
        color: #FFF;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .crypto_one_list li {
        list-style-type: disc;
        list-style-position: inside;

    }

    @media (max-width: 768px) {
        .h1 {
            font-family: "Days One", sans-serif;
            font-size: 40px;
            line-height: 102%;
            /* 30.6px */
        }

        section {
            padding: 0 0 327px !important;

        }

        #first_screen {
            padding: 0 0 327px;
        }

        .bg {
            display: none;
        }

        .bg-mobile {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 1100px;
        }
    }

    @media (max-width: 500px) {
        .h1 {
            font-family: "Days One", sans-serif;
            font-size: 30px;
        }

        .undertitle {
            font-size: 18px;
        }

        .buttons a {
            max-width: unset;
            width: 100%;
        }

        section {
            padding: 0 0 227px !important;
        }

        .bg-mobile {
            height: 1248px;
        }

    }
</style>