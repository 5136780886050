<template>
    <section class="p-0">
          <div class="myBrs">
              <div class="container">
                    <img :src="`/static/img/engine-page/${$t('engine.section-8.name')}.svg`" class="mw-100">
              </div>
          </div>
    </section>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
      section {
          background: #363957 !important;
          border-radius: 0 !important;
      }
  
      .h5 {
          font-family: "Days One", sans-serif;
          font-size: 24px;
          margin-bottom: 23px;
          font-weight: bold;
      }
  
      .myBrs {
          padding: 40px 0;
          border-radius: 70px 70px 0 0;
          background-color: #fff;
      }
  
      .myImg {
        margin-right: -40px;
      }
  
      @media(max-width: 768px) {
          .myBrs {
            border-radius: 20px 20px 0 0 !important;
            padding: 50px 0;
          }
      }
  
      @media (max-width: 500px) {
          .h5 {
              font-size: 18px;
              line-height: 89.743%;
          }
      }
  
  </style>