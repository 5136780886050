<template>
    <section id="how_it_works">
        <div class="brs">
            <div class="container">
                <h2 class="h2">{{ $t('howItwork.title') }}</h2>
                <div class="d-flex justify-content-center">
                    <img :src="`/static/img/aquiring-page/section-5/${$t('aquiring.image')}.svg`" class="mw-100 d-none d-md-block">
                    <img :src="`/static/img/aquiring-page/section-5/${$t('aquiring.imageMobile')}.svg`" class="mw-100 d-block d-md-none mx-auto">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    padding: 0 !important;
    border-radius: 0 !important;
    background: #000;
}
.brs {
    padding: 140px 0 150px;
    border-radius: 60px 60px 0 0;
    background-color: #fff;
    position: relative;
    z-index: 10;
    color: #000;
}
.h2 {
    margin-bottom: 70px;
}
.h5 {
    margin-bottom: 15px;
    color: #FFF;
    font-family: "Days One", sans-serif;
    font-size: 24px;
}
@media (max-width:768px) {
    .h2 {
        margin-bottom: 30px;
    }
    .brs {
        border-radius: 20px 20px 0 0;
        padding: 50px 0 50px;
      }
}
</style>