  <template>

  <Section1/>
  <Section2/>
  <Section3/>
  <Section4/>
  <Section6/>
  <Section7/>
  <Section8/>
  <Section9/>
  <Section10/>
  <EasyConnect/>
  <Other/>
  <Footer/>
  
  </template>
  
  <script>
    import Section1 from "@/components/engine-page/Section1.vue";
    import Section2 from "@/components/engine-page/Section2.vue";
    import Section3 from "@/components/engine-page/Section3.vue";
    import Section4 from "@/components/engine-page/Section4.vue";
    import Section6 from "@/components/engine-page/Section6.vue";
    import Section7 from "@/components/engine-page/Section7.vue";
    import Section8 from "@/components/engine-page/Section8.vue";
    import Section9 from "@/components/engine-page/Section9.vue";
    import Section10 from "@/components/engine-page/Section10.vue";
    import EasyConnect from "@/components/EasyConnect.vue";
    import Other from "@/components/Other.vue";
    import Footer from "@/components/Footer.vue";
  
  export default {
    name: 'EngineView',
      components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section6,
        Section7,
        Section8,
        Section9,
        Section10,
        Other,
        EasyConnect,
        Footer,
      },
    };
  </script>
  
<style scoped>
#what_you_take {
  border-radius: 0;
}
#easy_connect_section {
  border-radius: 60px 60px 0 0;
  margin-top: -40px;
}
@media(max-width: 768px) {
  section {
    border-radius: 20px 20px 0 0;
    padding: 50px 0;
  }
  .myBrs {
    border-radius: 20px 20px 0 0 !important;
    padding: 50px 0;
  }
  #easy_connect_section {
    border-radius: 20px 20px 0 0;
  }
  #other_section {
    border-radius: 0;
  }
}
</style>
  