import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import CryptoView from '../views/CryptoView.vue';
import AlternativeView from '../views/AlternativeView.vue';
import AquiringView from '../views/AquiringView.vue';
import EngineView from '../views/EngineView.vue';
import CardsView from '../views/CardsView.vue';
import AboutView from '../views/AboutView.vue';
import OurSolutionView from '../views/OurSolutionView.vue';
import WrapperView from '../views/WrapperView.vue';
import i18n from '../i18n';

const routes = [
  {
    path: '/:lang(en|ru)',
    component: WrapperView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          title: {
            en: 'PAYS.ZONE Online Payment solutions | Crypto Payment Service provider',
            ru: 'PAYS.ZONE | Эквайринг криптовалютных и классических платежей'
          },
          description: {
            en: 'PAYS.ZONE is a payment service provider for business worldwide. Card acquirer. Advisor in receiving e-payments. Payment aggregator & mass-payouts provider. Millions of users. More than 35 currencies. 7 years on market.',
            ru: 'PAYS.ZONE - эквайринг для вашего проекта. Приём платежей по всему миру. Более 35 валют и криптовалют. 7 лет на рынке. Широкая кастомизация. Быстрая интеграция.'
          }
        }
      },
      {
        path: 'crypto',
        name: 'crypto',
        component: CryptoView,
        meta: {
          title: {
            en: 'Crypto acquiring | Crypto Payment Service by PAYS.ZONE',
            ru: 'Криптоэквайринг | Сервис криптовалютных платежей от PAYS.ZONE'
          },
          description: {
            en: 'Start accepting payments in crypto with global acquiring service from PAYS.ZONE. Online businesses can now accept crypto payments worldwide. All-in-one solution. Wide functionality. Quick and secure.',
            ru: 'Начните принимать платежи в криптовалюте с помощью решения от PAYS.ZONE. По всему миру. Для разных видов бизнеса. Персонализированный подход. Широкий функционал. Быстро и безопасно.'
          }
        }
      },
      {
        path: 'alternative',
        name: 'alternative',
        component: AlternativeView,
        meta: {
          title: {
            en: 'Alternative payment gateways by PAYS.ZONE',
            ru: 'Альтернативные платежные шлюзы от PAYS.ZONE'
          },
          description: {
            en: 'Need personal approach? PAYS.ZONE create h2h integration, branded payments pages, suggest labeled Telegram-bots. Day-to-day crypto or mass payouts to any bank.',
            ru: 'Нужен индивидуальный подход? PAYS.ZONE предлагает h2h-интеграцию, брендированные платежные страницы, Telegram-ботов. Принимайте платежи на любых удобных онлайн платформах.'
          }
        }
      },
      {
        path: 'aquiring',
        name: 'aquiring',
        component: AquiringView,
        meta: {
          title: {
            en: 'Traditional acquiring | Payment solutions by PAYS.ZONE',
            ru: 'Классический эквайринг | Платежные решения от PAYS.ZONE'
          },
          description: {
            en: 'Accept payments with Pays.Zone. Secure transactions. Effective workflow. Turnkey solution in quick timeframes. Mobile, desktop options. Recurring payments. 7 years of experience.',
            ru: 'Принимайте платежи с PAYS.ZONE. Безопасные транзакции. Отработанный эффективный процесс. Готовые решения под ключ. 7 лет на рынке.'
          }
        }
      },
      {
        path: 'engine',
        name: 'engine',
        component: EngineView,
        meta: {
          title: {
            en: 'Crypto wallet and exchange SaaS by PAYS.ZONE',
            ru: 'SaaS криптосервис от PAYS.ZONE | Платформа для кошелька или обменника'
          },
          description: {
            en: 'Use PAYS.ZONE solution to build your own branded crypto platform. Personal consultant to help creating your custom system. Provide modern financial freedom. Enrich customer experience.',
            ru: 'Используйте разработки PAYS.ZONE для создания собственной брендированной крипто-платформы. Решение под ключ. Быстрая интеграция. 80+ направлений обмена.'
          }
        }
      },
      {
        path: 'our-solution',
        name: 'our-solution',
        component: OurSolutionView,
        meta: {
          title: {
            en: 'Custom individual gateways | PAYS.ZONE solutions',
            ru: 'Платежные решения от PAYS.ZONE'
          },
          description: {
            en: 'Expanding to other markets? PAYS.ZONE provide payment processing solutions designed for different types of business in wide variaty of regions. Cover all payment options with a full-stack solution!',
            ru: 'Выходите на другие рынки? PAYS.ZONE предлагает решения по обработке платежей, предназначенные для различных типов бизнеса в самых разных регионах. Охватите все варианты приема оплаты с помощью проверенного поставщика услуг!'
          }
        }
      },
      {
        path: 'cards',
        name: 'cards',
        component: CardsView,
        meta: {
          title: {
            en: 'PAYS.ZONE | Service of virtual and gift cards',
            ru: 'Сервис виртуальных и подарочных карт от PAYS.ZONE'
          },
          description: {
            en: 'Explore the most popular services and shops with PAYS.ZONE. Launch your own service of issuing gift cards. Instant emission. Easy access. Solution based on your location or business industry.',
            ru: 'Запустите свой собственный сервис по выпуску подарочных карт. Выпуск виртуальных карт европейских банков. Мгновенная эмиссия. 0% комиссии за платежи.'
          }
        }
      },
      {
        path: 'about',
        name: 'about',
        component: AboutView,
        meta: {
          title: {
            en: 'About PAYS.ZONE | Your global payment services provider',
            ru: 'О компании PAYS.ZONE | Ваш глобальный поставщик платежных услуг'
          },
          description: {
            en: 'PAYS.ZONE is a payment service provider for clients across industries worldwide. Crypto solutions. Own competent software from the end developer. 7 years of global experience.',
            ru: 'PAYS.ZONE - платёжный сервис для клиентов из разных отраслей по всему миру. Криптовалютные решения. Широкие возможности по интеграции. Наличие автоплатежей и многое другое. 7 лет опыта на глобальном рынке.'
          }
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/en', // Redirect to the default language if no match found
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  }
});

// Global navigation guard to ensure a default language
router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  if (!lang) {
    next('/en'); // Redirect to default language
  } else {
    if (lang !== i18n.global.locale.value) {
      i18n.global.locale.value = lang;
    }
    next();
  }
});

router.afterEach((to) => {
  const lang = to.params.lang || 'en';
  const title = to.meta.title ? to.meta.title[lang] : 'PAYS.ZONE';
  const description = to.meta.description ? to.meta.description[lang] : '';

  document.title = title;
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = description;
    document.head.appendChild(meta);
  }
});

export default router;
