<template>
  <section class="position-relative">
    <div class="container">
      <h2 class="h2" v-html="$t('about.section-2.title')"></h2>
      <div class="about_info">
        <div class="row">
          <div class="col-12 col-lg-6">
              <div class="year-descr" v-html="$t('about.section-2.years.year-description-1')"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 ms-auto">
            <div class="ms-auto year-descr" v-html="$t('about.section-2.years.year-description-2')"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="year-descr" v-html="$t('about.section-2.years.year-description-3')"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 ms-auto">
            <div class="ms-auto year-descr" v-html="$t('about.section-2.years.year-description-4')"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="year-descr" v-html="$t('about.section-2.years.year-description-5')"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 ms-auto">
            <div class="ms-auto year-descr" v-html="$t('about.section-2.years.year-description-6')"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  section {
    padding: 130px 0 150px;
    background-color: #fff;
    color: #fff;
    margin-top: -70px;
    height: fit-content;
    border-radius: 60px 60px 0 0;
    color: #161616;
  }

  .h2 {
    margin-bottom: 92px;
  }

  .year-descr {
    max-width: 300px;
  }

  .row {
    margin-bottom: 22px;
  }

  p {
    max-width: 526px;
    font-family: "Helvetica Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 17px;
  }

  .about_info {
    background: no-repeat top 20px left 50% url(/public/static/img/about-page/about_bg.svg);
    background-size: contain;
  }

  @media (max-width:768px) {

    section {
      border-radius: 20px 20px 0 0;
      padding: 50px 0 50px;
    }

    p {
      font-size: 16px;
      font-family: roboto;
    }

    .h2 {
      margin-bottom: 32px;
    }

    .year-descr {
      margin-bottom: 10px;
    }

    .about_info {
      background: none;
    }

  }

  @media (max-width:500px) {
    .year-descr {
      position: relative;
    }

    .year-descr:before {
      content: '';
      width: 26px;
      height: 26px;
      background: #363957;
      position: absolute;
      top: 0;
      left: -30px;
      border-radius: 50%;
    }

    .ms-auto.year-descr {
      margin-left: 0 !important;
    }
  }
</style>