<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <Header/> -->
  <router-view/>
  <SupportModal/>
</template>

<script>
 import SupportModal from "@/components/Support-modal.vue";
export default {
  name: 'App',
  components: {
    SupportModal
      },
  watch: {
    '$route.params.lang': {
      immediate: true,
      handler(newLang) {
        this.$i18n.locale = newLang;
      }
    }
  }
  
}
</script>

<style>
  @import "./css/main.css";
</style>
