<template>
  <section id="ifNoCrypto">
    <div class="brs">
        <div class="container">
                <h6 class="h6">{{ $t('crypto.section-4.title') }}</h6>
                <div class="subtitle">
                    <span>{{ $t('crypto.section-4.description') }}</span>
                    <img src="/static/img/crypto-page/section-4/1.svg">
                </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    background-color: #fff;
    padding: 0 !important;
    border-radius: 0 !important;
}
    .brs {
        padding: 77px 0 108px;
        background-color: #1E1D1E;
        border-radius: 60px 60px 0 0;
    }


    .h6 {
        text-align: center;
        margin-bottom: 42px;
        color: #FFF;
        font-family: "Days One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        z-index: 2;
    }

    .subtitle {
        position: relative;
        z-index: 0;
    }

    .subtitle span {
        padding: 33px 64px 44px;
        border-radius: 20px;
        background: #363957;
        font-size: 24px;
        text-align: center;
        display: block;
    }

    .subtitle img {
        position: absolute;
        left:-70px;
        top: -40px;
        z-index: -1;
    }

    @media(max-width:768px) {
        .brs {
            border-radius: 20px 20px 0 0;
        }
    }
    @media(max-width:500px) {

        .brs {
            padding: 50px 0;
        }
       .h6 {
            font-size: 18px;
            max-width: 260px;
            margin: 0 auto 22px;
       }
       .subtitle span {
            font-size: 16px;
            padding: 20px;
       }

    }
</style>