<template>
    <section class="p-0">
          <div class="myBrs">
              <div class="container">
                  <div class="row">
                      <div class="col-12 col-sm-6">
                          <img src="/static/img/engine-page/consultation.svg" class="mw-100 d-block d-sm-none mb_30">
                          <h5 class="h5">{{ $t('engine.section-6.title') }}</h5>
                          <div class="text" v-html="$t('engine.section-6.description')">
                          </div>
                      </div>
                      <div class="col-12 col-sm-6">
                          <div class="d-flex justify-content-center align-items-end w-100 h-100">
                              <img src="/static/img/engine-page/consultation.svg" width="318" height="185" class="mw-100 d-none d-sm-block myImg">
                          </div>
                      </div>
                  </div>
              
              </div>
          </div>
    </section>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
      section {
          color: #fff;
          background: linear-gradient(92deg, #000 3.21%, #201F20 99.75%) !important;
          border-radius: 0 !important;
      }
  
      .h5 {
          font-family: "Days One", sans-serif;
          font-size: 24px;
          margin-bottom: 23px;
          font-weight: bold;
      }
  
      .myBrs {
          padding: 40px 0;
          border-radius: 60px 60px 0 0;
          background-color: #363957;
      }

      .myImg {
        margin-right: -40px;
      }
  
      @media(max-width: 768px) {
          .myBrs {
            border-radius: 20px 20px 0 0 !important;
            padding: 50px 0;
          }
      }
  
      @media (max-width: 500px) {
          .h5 {
              font-size: 18px;
              line-height: 89.743%;
          }
      }
  
  </style>