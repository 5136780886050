<template>
  <section id="two">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h2 class="h2">
                        {{ $t('crypto.section-2.title') }}
                    </h2>
                </div>
            </div>
                
            <div class="grid-container">
                <div class="grid-item card-dark">
                    <h6 v-html="$t('crypto.section-2.cards.card-1.title')"></h6>
                    <img src="/static/img/crypto-page/section-2/5.svg" class="card-img">
                    <div v-html="$t('crypto.section-2.cards.card-1.description')"></div>
                </div>
                <div class="grid-item card-dark">
                    <h6 v-html="$t('crypto.section-2.cards.card-2.title')"></h6>
                    <img src="/static/img/crypto-page/section-2/2.svg" class="card-img">
                    <div v-html="$t('crypto.section-2.cards.card-2.description')"></div>
                </div>
                <div class="grid-item card-dark">
                    <h6 v-html="$t('crypto.section-2.cards.card-3.title')"></h6>
                    <img src="/static/img/crypto-page/section-2/1.svg" class="card-img">
                    <div v-html="$t('crypto.section-2.cards.card-3.description')"></div>
                </div>
                <div class="grid-item card-dark">
                    <h6 v-html="$t('crypto.section-2.cards.card-4.title')"></h6>
                    <img src="/static/img/crypto-page/section-2/4.svg" class="card-img">
                    <div v-html="$t('crypto.section-2.cards.card-4.description')"></div>
                </div>
                <div class="grid-item card-dark">
                    <h6 v-html="$t('crypto.section-2.cards.card-5.title')"></h6>
                    <img src="/static/img/crypto-page/section-2/3.svg" class="card-img">
                    <div v-html="$t('crypto.section-2.cards.card-5.description')"></div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    section {
        padding: 98px 0 86px;
        background-color: #fff;
        color: #161616;
        border-radius: 60px 60px 0 0;
    }

    .h2 {
        margin-bottom: 69px;
    }

    .list_item {
        display: flex;
        align-items: center;
        gap: 11px;
        max-width: 348px;
    }
    
    .list_items {
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: #1E1D1E;
        font-size: 25px;
        font-weight: 500;
        line-height: 87.2%; /* 21.8px */
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr); /* Три колонки в первом ряду */
        grid-template-rows: auto auto; /* Два ряда */
        grid-gap: 30px; /* Отступы между блоками */
        align-items: stretch;
    }
    
    .card-dark {
        color: #fff;
        padding: 20px;
        border-radius: 20px;
        background: #363957;
        position: relative;
    }

    .card-dark img {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 74px;
    }

    .card-dark h6 {
        font-family: "Days One", sans-serif;
        margin-bottom: 11px;
        font-size: 20px;
        line-height: normal;
    }

    .grid-item {
        grid-column: span 2; /* Занимает две колонки */
    }

    /* Сделаем блоки 4 и 5 на две колонки */
    .grid-item:nth-child(4), .grid-item:nth-child(5) {
        grid-column: span 3; /* Занимает две колонки */
    }

    @media(max-width:992px) {
        .grid-item {
            grid-column: span 3; /* Занимает две колонки */
        }
        .grid-item:last-child {
            grid-column: span 6;
        }
    }
    @media(max-width:768px) {
        .grid-item {
            grid-column: span 6 !important;
        }
        section {
            padding: 50px 0;
        }
    }
    @media(max-width:500px) {

        section {
            border-radius: 20px 20px 0 0;
        }
        .card-dark h6 {
            font-size: 18px;
        }
        .card-dark p {
            font-size: 16px;
        }
    }
</style>