<template>
    <section id="two">
        <div class="container">
            <div class="grid-container">
                <div class="grid-item card-dark">
                    <div class="text">
                        <h6>{{ $t('alternative.section-2.cards.card-1.title') }}</h6>
                        <div v-html="$t('alternative.section-2.cards.card-1.description')"></div>
                    </div>
                    <img src="/static/img/alternative-page/section-1/2.png" class="card-img">
                </div>
                <div class="grid-item card-dark">
                    <div class="text">
                        <h6>{{ $t('alternative.section-2.cards.card-2.title') }}</h6>
                        <p v-html="$t('alternative.section-2.cards.card-2.description')"></p>
                    </div>
                    <img src="/static/img/alternative-page/section-1/1.png" class="card-img">
                </div>
                <div class="grid-item card-dark">
                    <div class="text">
                        <h6>{{ $t('alternative.section-2.cards.card-3.title') }}</h6>
                        <p v-html="$t('alternative.section-2.cards.card-3.description')"></p>
                    </div>
                    <img src="/static/img/alternative-page/section-1/3.png" class="card-img">
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
section {
    padding: 0 0 75px;
    background-color: #fff;
    color: #161616;
    border-radius: 60px 60px 0 0;
    margin-top: -50px;
}

.h2 {
    margin-bottom: 69px;
}

.list_item {
    display: flex;
    align-items: center;
    gap: 11px;
    max-width: 348px;
}

.list_items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #1E1D1E;
    font-size: 25px;
    font-weight: 500;
    line-height: 87.2%;
    /* 21.8px */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Три колонки в первом ряду */
    grid-template-rows: auto auto;
    /* Два ряда */
    grid-gap: 30px;
    /* Отступы между блоками */
    align-items: stretch;
}

.card-dark {
    display: flex;
    flex-direction: column;
    color: #fff;
    border-radius: 20px;
    background: #363957;
    overflow: hidden;
    font-size: 18px;
    overflow: hidden;
}

.card-dark img {
    border-radius: 20px;
    margin-top: auto;
    width: 100%;
}

.card-dark .text {
    padding: 20px;
    padding-bottom: 30px;
}

.card-dark h6 {
    font-family: "Days One", sans-serif;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: normal;
}

.grid-item {
    margin-top: -30%;
}

.grid-item p {
    margin: 0;
}

@media (max-width:768px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .grid-item {
        margin-top: unset;
    }
    section {
        padding: 50px 0;
    }

    .card-dark img {
        max-width: 500px;
        margin: 0 auto;
        padding: 50px;
    }
    
}
@media(max-width: 500px) {
    .card-dark img {
        max-width: unset;
        padding: 50px 0 0;
        height: 102%;
    }
}

</style>