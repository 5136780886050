<template>
  <footer>
        <div class="myCard">
            <div class="left">
                <div class="logo">
                    <a href="/">
                        <img src="/static/img/logo_payszone.svg" alt="logo">
                    </a>
                    <span id="license_link"></span>
                </div>
                <nav class="d-none d-md-block">
                    <ul>
                        <li><router-link :to="`/${currentLang}/aquiring`">{{ $t('footer.menu.item-1') }}</router-link></li>
                        <li><router-link :to="`/${currentLang}/crypto`">{{ $t('footer.menu.item-2') }}</router-link></li>
                        <li><router-link :to="`/${currentLang}/alternative`">{{ $t('footer.menu.item-3') }}</router-link></li>
                        <li><router-link :to="`/${currentLang}/engine`">{{ $t('footer.menu.item-4') }}</router-link></li>
                        <li><router-link :to="`/${currentLang}/cards`">{{ $t('footer.menu.item-5') }}</router-link></li>
                        <li><router-link :to="`/${currentLang}/our-solution`">{{ $t('footer.menu.item-6') }}</router-link></li>
                    </ul>
                </nav>
            </div>
            <div class="right">
                <div class="social mb-3">
                    <a target="_blank" href="https://t.me/pays_zone"><img width="35" height="35" src="/static/img/ic_baseline-telegram.svg"></a>
                    <a target="_blank" href="https://www.instagram.com/pays.zone"><img width="35" height="35" src="/static/img/ri_instagram-fill.svg"></a>
                </div>
                <div class="links">
                    <a href="mailto:d@pays.zone">d@pays.zone</a>
                    <!-- <a href="tel:+900 000 00 01">900 000 00 01</a> -->
                </div>
            </div>
        </div>
  </footer>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import { computed, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  
  export default {
    setup() {
      const route = useRoute();
      const router = useRouter();
      const { locale } = useI18n();
  
      const lang = ['en', 'ru'];
      const isLogin = ref(false);
      const currentLang = computed(() => route.params.lang || 'en');
      const filteredLangs = computed(() => lang.filter(l => l !== currentLang.value));
  
      const toggleLogin = () => {
        isLogin.value = !isLogin.value;
      };
  
      const changeLanguage = (newLang) => {
        locale.value = newLang;
        router.push({ params: { lang: newLang } });
      };
  
      watch(
        () => route.params.lang,
        (newLang) => {
          locale.value = newLang;
        }
      );
  
      return {
        isLogin,
        currentLang,
        filteredLangs,
        toggleLogin,
        changeLanguage
      };
    }
  };
</script>

<style scoped>
footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 10px;
    background-color: #fff;
}

.myCard {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: #FFF;
    font-family: Inter, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

footer .myCard {
    max-width: 1266px;
    width: 100%;
    padding: 33px 74px 55px;
}

.left {
    display: flex;
    justify-content: space-between;
    max-width: 45%;
    width: 100%;
    gap: 20px;
}

.right {
    max-width: 55%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
}

.social {
    display: flex;
    gap: 8px;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: end;
}

.logo img {
    margin-bottom: 20px;
}

nav ul li:not(:last-child) {
    margin-bottom: 10px;
}

@media (max-width: 500px) {
    footer {
        padding: 20px 10px;
    }

    footer .logo img {
        width: 84px;
    }

    footer .myCard {
        padding: 20px 20px 56px;
    }
}

</style>