<template>
    <section id="five">
        <div class="container">
            <h2 class="h2">{{ $t('alternative.functions.title') }}</h2>
            <img src="/static/img/alternative-page/section-5/function.svg" class="d-block d-sm-none mb_30 mx-auto">
            <h5 class="h5">{{ $t('alternative.functions.subtitle') }}</h5>
            <div class="text" v-html="$t('alternative.functions.description')">
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    padding: 140px 0 138px;
    background-color: #363957;
    border-radius: 60px 60px 0 0;
    position: relative;
}

.h2 {
    margin-bottom: 60px;
}
.h5 {
    margin-bottom: 15px;
    color: #FFF;
    font-family: "Days One", sans-serif;
    font-size: 24px;
}
.text {
    font-size: 24px;
    max-width: 570px;
}

@media (max-width: 500px) {
    .h2 {
        margin-bottom: 50px;
        max-width: 263px;
    }
    .h5 {
        font-size: 18px;
    }
    .text {
        font-size: 16px;
    }
}

</style>