  <template>

  <Section1/>
  <Section2/>
  <ForWho/>
  <HowItWork/>
  <EasyConnect/>
  <Other/>
  <Footer/>
  
  </template>
  
  <script>
    import Section1 from "@/components/aquiring-page/Section1.vue";
    import Section2 from "@/components/aquiring-page/Section2.vue";
    import ForWho from "@/components/aquiring-page/ForWho.vue";
    import HowItWork from "@/components/aquiring-page/HowItWork.vue";
    import EasyConnect from "@/components/EasyConnect.vue";
    import Other from "@/components/Other.vue";
    import Footer from "@/components/Footer.vue";
  
  export default {
    name: 'aquiringView',
      components: {
        Section1,
        Section2,
        ForWho,
        HowItWork,
        Other,
        EasyConnect,
        Footer,
      },
    };
  </script>
  
<style scoped>
#easy_connect_section {
  background: #fff !important;
}
#easy_connect_section, #other_section {
  border-radius: 0;
}
@media(max-width: 768px) {
  section {
    border-radius: 20px 20px 0 0;
    padding: 50px 0;
  }
  .myBrs {
    border-radius: 20px 20px 0 0 !important;
    padding: 50px 0;
  }
  #easy_connect_section {
    border-radius: 20px 20px 0 0;
  }
}
</style>
  