  <template>

  <Section1/>
  <Section2/>
  <Section4/>
  <Section6/>
  <Footer/>
  
  </template>
  
  <script>
    import Section1 from "@/components/about-page/Section1.vue";
    import Section2 from "@/components/about-page/Section2.vue";
    import Section4 from "@/components/about-page/Section4.vue";
    import Section6 from "@/components/about-page/Section6.vue";
    import Footer from "@/components/Footer.vue";
  
  export default {
    name: 'AboutView',
      components: {
        Section1,
        Section2,
        Section4,
        Section6,
        Footer,
      },
    };
  </script>
  
<style scoped>
#easy_connect_section, #other_section {
  border-radius: 0;
}
@media(max-width: 768px) {
  section {
    border-radius: 20px 20px 0 0;
    padding: 50px 0;
  }
  .myBrs {
    border-radius: 20px 20px 0 0 !important;
    padding: 50px 0;
  }
  #easy_connect_section {
    border-radius: 20px 20px 0 0;
  }
}
</style>
  