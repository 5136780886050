<template>
    <section id="fourth">
        <div class="brs">
            <div class="container">
                <h2 class="h2">{{$t('about.section-4.title')}}</h2>
                <div class="statistics">
                    <div class="row">
                        <div class="col-12 col-md-6 ms-auto">
                            <div class="item">
                                <h3 class="title">{{$t('about.section-4.items.item-1.h3')}}</h3>
                                <span class="subtitle">{{$t('about.section-4.items.item-1.span')}}</span>
                            </div>
                            <div class="item">
                                <h3 class="title">{{$t('about.section-4.items.item-2.h3')}}</h3>
                                <span class="subtitle">{{$t('about.section-4.items.item-2.span')}}</span>
                            </div>
                            <div class="item">
                                <h3 class="title">{{$t('about.section-4.items.item-3.h3')}}</h3>
                                <span class="subtitle">{{$t('about.section-4.items.item-3.span')}}</span>
                            </div>
                            <div class="item">
                                <h3 class="title">{{$t('about.section-4.items.item-4.h3')}}</h3>
                                <span class="subtitle">{{$t('about.section-4.items.item-4.span')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
}
</script>

<style scoped>
section {
    background: #fff;
    border-radius: 0 !important;
    padding: 0 !important;
}

.brs {
    padding: 109px 0 104px;
    background: linear-gradient(92deg, #000 3.21%, #201F20 99.75%);
    border-radius: 60px 60px 0 0;
}

.h2 {
    margin-bottom: 86px;
}

.title {
    margin-bottom: 10px;
    font-family: "Helvetica Neue";
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 74.3%;
}

.subtitle {
    margin-bottom: 30px;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 274px;
}

@media(max-width: 1024px) {
    
}

@media(max-width: 768px) {
    .brs {
        padding: 30px 0 50px;
        border-radius: 20px 20px 0 0;
    }

    .h2 {
        max-width: 206px;
        margin-bottom: 30px;
    }

    .title {
        font-size: 36px;
    }

    .subtitle {
        font-size: 16px;
    }
}
</style>