export default {
  "header": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "menu": {
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])}
    }
  },
  "footer": {
    "menu": {
      "item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic aquiring"])},
      "item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto aquiring"])},
      "item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative gateways"])},
      "item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label crypto service"])},
      "item-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual and gift cards"])},
      "item-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom gateways"])}
    }
  },
  "home": {
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone of <span class='light_blue'>fast</span> payments"])},
      "special-word-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accurate"])},
      "special-word-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crypto"])},
      "special-word-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secure"])},
      "special-word-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["easy"])},
      "special-word-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["global"])},
      "special-word-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fast"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow your business - accept all payments worldwide with no limits"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
      "card1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic <br> aquiring"])},
      "card2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto <br> aquiring"])},
      "card3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative <br> gateways"])},
      "card4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label <br> crypto service"])},
      "card5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual <br>  and gift cards"])},
      "card6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom <br> gateways"])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Increase</span> income <br> with Pays.Zone"])},
      "list-item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted financial services provider"])},
      "list-item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible and customizable settings for each merchant"])},
      "list-item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tremendous data analytics capabilities"])},
      "list-item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High conversion payment flow"])},
      "list-item-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC / Mobile / tablet friendly interfaces"])}
    },
    "section3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>All-in-1<span> <br> solutions"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All payment methods are carefully developed by high-skilled team of financiers and cover most of merchants' demands."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all solutions"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>For a variety</span> <br> of businesses"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global payment service provider for online industry with different risk levels - crypto services, e-commerce, online gaming, influencers, digital platforms and many others."])}
    },
    "section5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Flexible</span> <br> API"])},
      "description": {
        "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wide integration capabilities, plugins for popular CMS,frammeworks and services."])},
        "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to embed h2h, webview and SDK."])},
        "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full support by technical specialists and account managers on the whole integration path."])}
      }
    },
    "section6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Easy</span> <br> onboarding"])},
      "description": {
        "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast and rational KYB procedure."])},
        "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full support and accounting from request till final testing process."])}
      }
    }
  },
  "easyConnect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply <br> for services now"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is easy and convenient to work with our company, as we offer flexible terms of cooperation and intuitive tools for doing business."])},
    "items": {
      "item-1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our account manager will contact you as soon as possible, will listen to you carefully and offer you the best options of cooperation."])}
      },
      "item-2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become approved"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Give us a bit of time and become approved!</p> <span>Thanks to our wide range of payment methods we are able to find solution literally for every merchant.</span>"])}
      },
      "item-3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting payments"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Once approved, you will start accepting payments within few days</p> <span>dont hesitate and 👇</span>"])}
      }
    },
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave request"])}
  },
  "howItwork": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])}
  },
  "OtherSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other <br> solutions"])},
    "cards": {
      "card-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic aquiring"])},
      "card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto aquiring"])},
      "card-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative gateways"])},
      "card-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label crypto service"])},
      "card-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual and gift cards"])},
      "card-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom gateways"])}
    }
  },
  "crypto": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto aquiring"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future is here! <br> Accept crypto payments from you customers."])},
      "list": {
        "li-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No limits or restrictions"])},
        "li-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy KYC / KYB"])},
        "li-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worldwide with any fiat currency"])}
      }
    },
    "section-1-5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-in-one <br> solution for"])},
      "card-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce"])},
      "card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto wallets <br> and services"])},
      "card-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate <br> companies"])},
      "card-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital <br> platforms"])},
      "card-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online <br> gaming"])},
      "card-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram bots"])},
      "card-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations <br> and charity"])},
      "card-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
    },
    "section-2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why you need crypto aquiring?"])},
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You own business <br> crypto account"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Accept crypto payments and store funds just like in ordinary bank.</p> <p>Convert, withdraw and store crypto securely.</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low comission"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Individual comission for all incoming payments from 0%.</p> <p>Special conditions for merchants with $100 000 monthly turnover.</p> "])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The world is yours!"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto aquiring - is a real <br> chance to accept payments worldwide, even from residents of remotest corners of the world!"])}
        },
        "card-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wide functionality"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Withdraw funds 24/7 even on holidays and day-offs.</p> <p>1 click crypto convert. Withdraw to partner crypto exchanges.</p>"])}
        },
        "card-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 support"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have any troubles or questions? <br> Our friendly support is willing to help day and night!"])}
        }
      }
    },
    "section-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice generation"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice is generated in administrative panel or automatically via integration"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer selects payment method and makes payment"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds deposit"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds are immideately deposited to your merchant account balance"])}
        }
      }
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What if customer doesn’t have crypto?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every user from approved locations has a possibility to buy crypto during payment flow"])}
    },
    "scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheme_eng"])},
    "scheme_mob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheme-mobile_eng"])}
  },
  "alternative": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative payment methods"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payments on any digital platform in a convenient form"])}
    },
    "section-2": {
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h2h integration"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Integrate our processing using powerful and easy-to-understand API.</p> <p>Average integration time - 3 days</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pages"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect your users to our mobile-friendly payment pages with broad branding possibilities"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telagram-bot"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will create standalone payment platform - branded telegram bot where your customers can make payments"])}
        }
      }
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantages"])},
      "benefit-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High conversion"])},
      "benefit-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-to-day <br> payouts in crypto"])},
      "benefit-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass payouts to any banks"])},
      "benefit-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deposit <br> or setup fees"])},
      "benefit-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99,9% real service uptime"])},
      "benefit-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatible with <br> any type of business"])}
    },
    "forWho": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable for"])},
      "items": {
        "item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptoservices - wallets and exchanges"])},
        "item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate companies"])},
        "item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce"])},
        "item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital <br> platforms"])},
        "item-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram-bots"])},
        "item-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations <br> and charity"])},
        "item-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online gaming"])}
      },
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])}
    },
    "functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core features"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative panel"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Powerful administrative panel for managing finances and other important data.</p> <p>Full reports, downloads in excel format, customizable analytics, balance monitoring and many other essential features.</p>"])}
    },
    "api": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API docs"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Simple and easy to understand integration documentation.</p> <p>Average time of project launch is 3 days.</p> <p>All the above described integration options are available.</p> <p>Full tech support during the whole dev process.</p>"])}
    },
    "withdrawal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USDT withdrawals"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>All payments, made in any fiat currency - automatically converted into USDT.</p> <p>Balance withdrawal is available 24/7 and is processed in the same day.</p>"])}
    },
    "telegram": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram notifications"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A full range of notifications on every action taken through special telegram bots."])}
    }
  },
  "aquiring": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquiring"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='undertitle mb-3'>Pays.Zone provides direct payment services for a variety of businesses.</p> <p class='undertitle mb-3'>Our payment gateway enables users to make direct secure payment with adaptive risk management.</p>"])}
    },
    "section-2": {
      "accordion": {
        "item-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web payments"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payments through desktop or mobile websites"])}
        },
        "item-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDK payments"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept credit card payments through mobile apps"])}
        },
        "item-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring payments"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to accept subscription payments? Make it easy with pays.zone recurring payment module"])}
        },
        "item-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment link"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate via API or through administrative panel special link for secure payments"])}
        },
        "item-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast Payments System"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payments fo your goods and services from russian customers using Fast Payments System"])}
        }
      }
    },
    "forWho": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable for"])},
      "cards": {
        "card-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptoservices - <br> wallets and exchanges"])},
        "card-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-commerce"])},
        "card-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital <br> platforms"])},
        "card-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram-bots"])},
        "card-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations <br> and charity"])},
        "card-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
      }
    },
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["big_eng"])},
    "imageMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mb_en"])}
  },
  "engine": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>White-label <br> crypto service</span>"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch your own crypto service - wallet, exchange or even a cryptobank with our powerful crypto core solution"])},
      "button-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View demo"])},
      "button-1-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://bsb.money/"])},
      "button-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])}
    },
    "section-2": {
      "cards": {
        "card-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500+ exchange <br> directions"])},
        "card-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic <br> solutions"])},
        "card-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch in <br> any location"])},
        "card-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully <br> customizable solution"])},
        "card-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensive <br> analytics and reports"])}
      }
    },
    "section-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you will get?"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branded platform"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptoservice is launched with your own domain and design"])}
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative panel"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With service settings, extensive analytics, database containing users and transactions"])}
    },
    "section-5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal manager"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who will follow your business on all stages of integration and after release"])}
    },
    "section-6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have huge expirience in launching cryptoservices. We can help you in jurisdiction choice, development  and setting business flow"])}
    },
    "section-8": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flag_map_en"])}
    },
    "section-9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core <br> features"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose some functions, available in your service or you can get the whole service, containing everything"])},
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration <br> and verification"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Users will have possibility to register and verify their service accounts.</p> <p>Standalone verification service can be integrated or you can use manual document processing module.</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts <br> and balance"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users have ability to store their funds in their accounts and see their actual balance and transaction history."])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant <br> exchange"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Possibility of exchange request processing without registration of account.</p> <p>Suitable for most exchange services.</p>"])}
        },
        "card-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices and payment links"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can topup their balances, making a special payment link or generating invoices for other people."])}
        },
        "card-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment <br> methods settings"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>You can add, delete or activate any payment method, suitable for users in your jurisdiction.</p> <p>Set up currency or payment method rates whenever you need.</p>"])}
        },
        "card-6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocompany <br> registration"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need cryptolicence, we will help you in company registration process in jurisdiction, best suitable for your business."])}
        }
      }
    },
    "section-10": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution costs"])},
      "cards": {
        "card-1": {
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup fee"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time payment for service"])}
        },
        "card-2": {
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal support"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License, company and bank account"])}
        },
        "card-3": {
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction fees"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee per every user's transaction"])}
        }
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Licence fee varies from country to country"])}
    }
  },
  "cards": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Virtual <br> and gift cards</span>"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch your own service issuing <br> virtual and gift cards using Pays.Zone API"])}
    },
    "section-2": {
      "cards": {
        "card-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual cards emisson of American and Europe banks"])},
        "card-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift cards emission of more than 100 services"])},
        "card-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any volume of card issuance"])},
        "card-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0% comission for payments"])}
      }
    },
    "section-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No 3DS cards"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant payment without sms or push notification"])}
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple / Google pay compitable"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards can be bounded to Apple Pay, Google Pay and Samsung Pay"])}
    },
    "section-6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy access"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card requisites, balance and payment history is shown in user’s account"])}
    },
    "section-7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant emission"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right after purchase card is available in account and is ready for shopping"])}
    },
    "section-8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant emission"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can purchase cards with a variety of payment methods form fiat to crypto"])}
    },
    "section-9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you can buy with cards?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For purchases in online and offline shops all  over the world"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and 50+ services"])}
    }
  },
  "about": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About <br> <span class='light_blue'>Pays.Zone</span>"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2>Our mission</h2> <p class='m-0 text_container'>Is to provide easy payments all over the world without limits<p>"])}
    },
    "section-2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us?"])},
      "years": {
        "year-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>We have 7 years of fintech experience.</p>"])},
        "year-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Our ecosystem contains more than 10 tools, simplifying fiat and crypto transactions.</p>"])},
        "year-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>We use new technologies including AI.</p>"])},
        "year-description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We optimize tools and increase conversion of international payments permanently."])},
        "year-description-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are actively implementing alternative payment methods and cryptocurrency"])},
        "year-description-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer flexible and tranparent fee policy"])}
      }
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays.Zone in numbers:"])},
      "items": {
        "item-1": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 transactions"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed every second"])}
        },
        "item-2": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 currencies"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed via our service"])}
        },
        "item-3": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 employees"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labours daily  to enhance our service"])}
        },
        "item-4": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millions of users"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our service to make easy and secure payments"])}
        }
      }
    },
    "section-6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays.Zone products"])},
      "cards": {
        "card-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic <br> aquiring"])},
        "card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto <br> aquiring"])},
        "card-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative <br> gateways"])},
        "card-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label crypto <br> service"])},
        "card-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual and <br> gift cards"])},
        "card-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom <br> gateways"])}
      },
      "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And we are working on:"])},
      "list": {
        "list-item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocurrency Mining and staking solutions"])},
        "list-item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New payment gateways in Latin America"])},
        "list-item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New payment gateways in Asia"])},
        "list-item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhancing current solutions"])}
      }
    }
  },
  "ourSolution": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Pays.Zone<span> <br>solutions"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Our team develops stable, secure and high-conversion payment solutions.</p> <p>Accepting payments with Pays.Zone, your business will operate 24/7, you will be able to easily expand your geographical presence and open new areas of commercial activity.</p> <p>Working with us, you can you can be sure that you are purchasing software from the end developer.</p>"])}
    },
    "section-4": {
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic <br> aquiring"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Pays.Zone provides direct payment services for a variety of businesses.</p> <p>Our payment gateway enables users to make direct secure payment with adaptive risk management.</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto <br> aquiring"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Secure and stable gateway to accept cryptocurrency payments. Inegrates with any fiat currency.</p> <p>Accept payments without borders or any inspections.</p>"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative <br> gateways"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Accept payments on any digital platform in a convenient form. Suitable for any business industry.</p>"])}
        },
        "card-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label <br> crypto service"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Launch your own crypto service - wallet, exchange or even a cryptobank with our powerful crypto core solution</p>"])}
        },
        "card-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual and <br> gift cards"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Launch your own service issuing virtual and gift cards using Pays.Zone API</p>"])}
        },
        "card-6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need custom payment gateway?"])}
        }
      },
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["develop"])}
    }
  },
  "modal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send mail"])},
    "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "mail-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mail"])},
    "text-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on the “Submit” button, you agree to the terms of service and privacy policy."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail sent"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in all the fields"])},
    "error-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending message"])}
  }
}