<template>
    <section class="p-0">
        <div class="myBrs">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-7">
                        <h2 class="h2" v-html="$t('engine.section-10.title')"></h2>
                    </div>
                    <div class="cards">
                        <div class="row myGap">
                            <div class="col-12 col-md-4">
                                <div class="myCard">
                                    <p>{{$t('engine.section-10.cards.card-1.price')}}</p>
                                    <span>{{$t('engine.section-10.cards.card-1.description')}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="myCard">
                                    <p>{{$t('engine.section-10.cards.card-2.price')}}</p>
                                    <span>{{$t('engine.section-10.cards.card-2.description')}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="myCard">
                                    <p>{{$t('engine.section-10.cards.card-3.price')}}</p>
                                    <span>{{$t('engine.section-10.cards.card-3.description')}}</span>
                                    <img src="/static/img/engine-page/pattern.svg" class="d-none d-md-block pattern">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <span class="center_span">{{$t('engine.section-10.subtitle')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    color: #fff;
    background: #fff;
    border-radius: 0 !important;
    overflow: hidden;
}

.myBrs {
    background: linear-gradient(92deg, #000 3.21%, #201F20 99.75%);
    border-radius: 60px 60px 0 0;
    padding: 115px 0 135px;
}

.h2 {
    font-family: "Days One", sans-serif;
    font-size: 40px;
    margin-bottom: 20px;
}

.text {
    margin-bottom: 55px;
}

.myCard {
    height: 180px;
    padding: 20px;
    font-family: "Helvetica Neue", sans-serif;
}

.myCard p {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 15px;
}

.myCard span {
    font-size: 24px;
}

.myCard:last-child {
    position: relative;
}

.center_span {
    max-width: 353px;
    display: block;
    margin: 22px auto;
}

.pattern {
    position: absolute;
    bottom: -50px;
    right: -100px;
}

.myGap {
    row-gap: 30px;
}

@media (max-width: 1024px) {
    .myCard span {
        font-size: 20px;
    }
    .myCard p {
        font-size: 45px;
    }
}

@media (max-width: 768px) {
    .myBrs {
        padding: 50px 0 80px;
        border-radius: 20px 20px 0 0;
    }

    .h2 {
        margin-bottom: 50px;
    }
    

}

@media (max-width: 500px) {
    .h2 {
        font-size: 25px;
        line-height: 87.2%;
        margin-bottom: 50px;
    }

    .text {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .myCard {
        height: 152px;
    }

    .myCard span {
        font-size: 18px;
    }

    .center_span {
        font-size: 16px;
    }

}
</style>