<template>
    <section id="other_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-5">
                    <h2 class="h2" v-html="$t('OtherSolutions.title')"></h2>
                </div>
            </div>
            <div class="swiper_container">
                <swiper :grabCursor="true" :spaceBetween="30" :slides-per-view="4.5" :autoplay="true" :modules="modules" :loop="true"
                :breakpoints="{
                    '0': {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    '768': {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                    '1024': {
                      slidesPerView: 3.5,
                    },
                    '1440': {
                        slidesPerView: 4.5,
                      },
                  }"
                class="mySwiper">
                    <swiper-slide id="slide-1">
                        <router-link :to="`/${currentLang}/aquiring`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-1')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="57" viewBox="0 0 47 57"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M35.1951 26.9292L43.9996 32.0705C45.5611 32.9824 46.5211 34.6547 46.5211 36.4629V46.7721C46.5211 48.5803 45.5611 50.2526 43.9996 51.1645L35.1951 56.3058C33.6103 57.2312 31.65 57.2312 30.0653 56.3058L21.2608 51.1645C19.6993 50.2526 18.7393 48.5803 18.7393 46.7721V36.4629C18.7393 34.6547 19.6993 32.9824 21.2608 32.0705L30.0653 26.9292C31.65 26.0038 33.6103 26.0038 35.1951 26.9292ZM33.3051 30.1656C32.8881 29.9221 32.3722 29.9221 31.9552 30.1656L23.1507 35.307C22.7398 35.547 22.4871 35.987 22.4871 36.4629V46.7721C22.4871 47.2479 22.7398 47.688 23.1507 47.928L31.9552 53.0694C32.3722 53.3129 32.8881 53.3129 33.3051 53.0694L42.1096 47.928C42.5206 47.688 42.7732 47.2479 42.7732 46.7721V36.4629C42.7732 35.987 42.5206 35.547 42.1096 35.307L33.3051 30.1656Z"
                                fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M16.4558 0.694054L25.2603 5.83542C26.8218 6.74726 27.7818 8.41955 27.7818 10.2278V20.537C27.7818 22.3452 26.8218 24.0175 25.2603 24.9294L16.4558 30.0707C14.8711 30.9961 12.9107 30.9961 11.326 30.0707L2.52151 24.9294C0.960009 24.0175 0 22.3452 0 20.537V10.2278C0 8.41955 0.960004 6.74726 2.5215 5.83542L11.326 0.694055C12.9107 -0.23135 14.8711 -0.231352 16.4558 0.694054ZM14.5659 3.93054C14.1489 3.68701 13.633 3.68701 13.2159 3.93054L4.41144 9.07191C4.00052 9.31186 3.74789 9.75194 3.74789 10.2278V20.537C3.74789 21.0128 4.00052 21.4529 4.41144 21.6929L13.2159 26.8342C13.633 27.0778 14.1489 27.0778 14.5659 26.8342L23.3704 21.6929C23.7813 21.4529 24.0339 21.0128 24.0339 20.537V10.2278C24.0339 9.75194 23.7813 9.31186 23.3704 9.07191L14.5659 3.93054Z"
                                fill="white" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M25.8259 12.4731L34.6304 17.6145C36.1919 18.5263 37.1519 20.1986 37.1519 22.0068V32.316C37.1519 34.1243 36.1919 35.7966 34.6304 36.7084L25.8259 41.8498C24.2412 42.7752 22.2809 42.7752 20.6961 41.8498L11.8916 36.7084C10.3301 35.7966 9.37012 34.1243 9.37012 32.316V22.0068C9.37012 20.1986 10.3301 18.5263 11.8916 17.6145L20.6961 12.4731C22.2808 11.5477 24.2412 11.5477 25.8259 12.4731ZM23.936 15.7096C23.519 15.4661 23.0031 15.4661 22.5861 15.7096L13.7816 20.851C13.3706 21.0909 13.118 21.531 13.118 22.0068V32.316C13.118 32.7919 13.3706 33.232 13.7816 33.4719L22.5861 38.6133C23.0031 38.8568 23.519 38.8568 23.936 38.6133L32.7405 33.4719C33.1514 33.232 33.4041 32.7919 33.4041 32.316V22.0068C33.4041 21.531 33.1514 21.0909 32.7405 20.851L23.936 15.7096Z"
                                fill="#6D7AF2" />
                            </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-2">
                        <router-link :to="`/${currentLang}/crypto`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-2')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="44" viewBox="0 0 53 44"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.16986 39.018H5.63427V43.1878H1.48924C0.666755 43.1878 0 42.5211 0 41.6986V36.6724H4.16986V39.018ZM0 26.6201H4.16986V16.5678H0V26.6201ZM0 6.51541H4.16986V4.16986H5.63428V0H1.48924C0.666754 0 0 0.666755 0 1.48924V6.51541ZM13.9244 0V4.16986H22.2144V0H13.9244ZM30.5045 0V4.16986H38.7946V0H30.5045ZM47.0847 0V4.16986H48.5491V6.51541H52.719V1.48924C52.719 0.666754 52.0522 0 51.2297 0H47.0847ZM52.719 16.5678H48.5491V26.6201H52.719V16.5678ZM52.719 36.6724H48.5491V39.018H47.0847V43.1878H51.2297C52.0522 43.1878 52.719 42.5211 52.719 41.6986V36.6724ZM38.7946 43.1878V39.018H30.5045V43.1878H38.7946ZM22.2144 43.1878V39.018H13.9244V43.1878H22.2144Z"
                                fill="white" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.16986 4.16986V13.5521H20.8493V4.16986H4.16986ZM1.48924 0C0.666754 0 0 0.666754 0 1.48924V16.2327C0 17.0552 0.666754 17.7219 1.48924 17.7219H23.5299C24.3524 17.7219 25.0192 17.0552 25.0192 16.2327V1.48924C25.0192 0.666754 24.3524 0 23.5299 0H1.48924Z"
                                fill="#6D7AF2" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M31.8701 29.3378V39.0179H48.5495V29.3378H31.8701ZM29.1894 25.168C28.3669 25.168 27.7002 25.8347 27.7002 26.6572V41.6985C27.7002 42.521 28.3669 43.1877 29.1894 43.1877H51.2301C52.0526 43.1877 52.7194 42.521 52.7194 41.6985V26.6572C52.7194 25.8347 52.0526 25.168 51.2301 25.168H29.1894Z"
                                fill="#6D7AF2" />
                            </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-3">
                        <router-link :to="`/${currentLang}/alternative`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-3')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="48" viewBox="0 0 56 48"
                            fill="none">
                            <path
                                d="M20.7004 37.3053C20.7004 43.0215 16.0664 47.6555 10.3502 47.6555C4.63394 47.6555 0 43.0215 0 37.3053C0 31.589 4.63394 26.9551 10.3502 26.9551C16.0664 26.9551 20.7004 31.589 20.7004 37.3053Z"
                                fill="white" />
                            <path
                                d="M55.6975 10.3502C55.6975 16.0664 51.0635 20.7004 45.3473 20.7004C39.631 20.7004 34.9971 16.0664 34.9971 10.3502C34.9971 4.63394 39.631 0 45.3473 0C51.0635 0 55.6975 4.63394 55.6975 10.3502Z"
                                fill="white" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M45.6179 8.35748C46.7596 8.50723 47.5637 9.55416 47.414 10.6959L44.9736 29.3009C44.8238 30.4426 43.7769 31.2467 42.6352 31.0969C41.4935 30.9472 40.6894 29.9003 40.8391 28.7586L42.6249 15.1443L11.6197 38.9589C10.7065 39.6603 9.39761 39.4886 8.6962 38.5754C7.99479 37.6622 8.16648 36.3533 9.07967 35.6519L40.0849 11.8373L26.4706 10.0516C25.3289 9.90181 24.5247 8.85488 24.6745 7.71318C24.8243 6.57149 25.8712 5.76736 27.0129 5.91711L45.6179 8.35748Z"
                                fill="#6D7AF2" />
                        </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-4">
                        <router-link :to="`/${currentLang}/engine`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-4')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="41" viewBox="0 0 60 41"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M20.217 4.16986C11.3544 4.16986 4.16986 11.3544 4.16986 20.217C4.16986 29.0796 11.3544 36.2642 20.217 36.2642C21.3685 36.2642 22.3019 37.1976 22.3019 38.3491C22.3019 39.5006 21.3685 40.434 20.217 40.434C9.05147 40.434 0 31.3826 0 20.217C0 9.05147 9.05147 0 20.217 0C31.3826 0 40.434 9.05147 40.434 20.217C40.434 21.3685 39.5006 22.3019 38.3491 22.3019C37.1976 22.3019 36.2642 21.3685 36.2642 20.217C36.2642 11.3544 29.0796 4.16986 20.217 4.16986Z"
                                fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M39.2039 36.2642C48.0665 36.2642 55.251 29.0797 55.251 20.2171C55.251 11.3545 48.0665 4.16991 39.2039 4.1699C38.0524 4.1699 37.119 3.23645 37.119 2.08497C37.119 0.9335 38.0524 4.39083e-05 39.2039 4.40089e-05C50.3694 4.49851e-05 59.4209 9.05151 59.4209 20.2171C59.4209 31.3826 50.3694 40.4341 39.2039 40.4341C28.0383 40.4341 18.9869 31.3826 18.9869 20.2171C18.9869 19.0656 19.9203 18.1321 21.0718 18.1321C22.2233 18.1321 23.1567 19.0656 23.1567 20.2171C23.1567 29.0797 30.3413 36.2642 39.2039 36.2642Z"
                                fill="white" />
                            <path class="hover_dark"
                                d="M26.7673 20.3121C26.7673 23.8773 23.8771 26.7675 20.3118 26.7675C16.7466 26.7675 13.8564 23.8773 13.8564 20.3121C13.8564 16.7469 16.7466 13.8567 20.3118 13.8567C23.8771 13.8567 26.7673 16.7469 26.7673 20.3121Z"
                                fill="#6D7AF2" />
                            <path class="hover_dark"
                                d="M44.8044 20.3121C44.8044 23.8773 41.9142 26.7675 38.349 26.7675C34.7837 26.7675 31.8936 23.8773 31.8936 20.3121C31.8936 16.7469 34.7837 13.8567 38.349 13.8567C41.9142 13.8567 44.8044 16.7469 44.8044 20.3121Z"
                                fill="#6D7AF2" />
                            </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-5">
                        <router-link :to="`/${currentLang}/aquiring`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-1')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="57" viewBox="0 0 47 57"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M35.1951 26.9292L43.9996 32.0705C45.5611 32.9824 46.5211 34.6547 46.5211 36.4629V46.7721C46.5211 48.5803 45.5611 50.2526 43.9996 51.1645L35.1951 56.3058C33.6103 57.2312 31.65 57.2312 30.0653 56.3058L21.2608 51.1645C19.6993 50.2526 18.7393 48.5803 18.7393 46.7721V36.4629C18.7393 34.6547 19.6993 32.9824 21.2608 32.0705L30.0653 26.9292C31.65 26.0038 33.6103 26.0038 35.1951 26.9292ZM33.3051 30.1656C32.8881 29.9221 32.3722 29.9221 31.9552 30.1656L23.1507 35.307C22.7398 35.547 22.4871 35.987 22.4871 36.4629V46.7721C22.4871 47.2479 22.7398 47.688 23.1507 47.928L31.9552 53.0694C32.3722 53.3129 32.8881 53.3129 33.3051 53.0694L42.1096 47.928C42.5206 47.688 42.7732 47.2479 42.7732 46.7721V36.4629C42.7732 35.987 42.5206 35.547 42.1096 35.307L33.3051 30.1656Z"
                                fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M16.4558 0.694054L25.2603 5.83542C26.8218 6.74726 27.7818 8.41955 27.7818 10.2278V20.537C27.7818 22.3452 26.8218 24.0175 25.2603 24.9294L16.4558 30.0707C14.8711 30.9961 12.9107 30.9961 11.326 30.0707L2.52151 24.9294C0.960009 24.0175 0 22.3452 0 20.537V10.2278C0 8.41955 0.960004 6.74726 2.5215 5.83542L11.326 0.694055C12.9107 -0.23135 14.8711 -0.231352 16.4558 0.694054ZM14.5659 3.93054C14.1489 3.68701 13.633 3.68701 13.2159 3.93054L4.41144 9.07191C4.00052 9.31186 3.74789 9.75194 3.74789 10.2278V20.537C3.74789 21.0128 4.00052 21.4529 4.41144 21.6929L13.2159 26.8342C13.633 27.0778 14.1489 27.0778 14.5659 26.8342L23.3704 21.6929C23.7813 21.4529 24.0339 21.0128 24.0339 20.537V10.2278C24.0339 9.75194 23.7813 9.31186 23.3704 9.07191L14.5659 3.93054Z"
                                fill="white" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M25.8259 12.4731L34.6304 17.6145C36.1919 18.5263 37.1519 20.1986 37.1519 22.0068V32.316C37.1519 34.1243 36.1919 35.7966 34.6304 36.7084L25.8259 41.8498C24.2412 42.7752 22.2809 42.7752 20.6961 41.8498L11.8916 36.7084C10.3301 35.7966 9.37012 34.1243 9.37012 32.316V22.0068C9.37012 20.1986 10.3301 18.5263 11.8916 17.6145L20.6961 12.4731C22.2808 11.5477 24.2412 11.5477 25.8259 12.4731ZM23.936 15.7096C23.519 15.4661 23.0031 15.4661 22.5861 15.7096L13.7816 20.851C13.3706 21.0909 13.118 21.531 13.118 22.0068V32.316C13.118 32.7919 13.3706 33.232 13.7816 33.4719L22.5861 38.6133C23.0031 38.8568 23.519 38.8568 23.936 38.6133L32.7405 33.4719C33.1514 33.232 33.4041 32.7919 33.4041 32.316V22.0068C33.4041 21.531 33.1514 21.0909 32.7405 20.851L23.936 15.7096Z"
                                fill="#6D7AF2" />
                            </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-6">
                        <router-link :to="`/${currentLang}/crypto`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-2')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="44" viewBox="0 0 53 44"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.16986 39.018H5.63427V43.1878H1.48924C0.666755 43.1878 0 42.5211 0 41.6986V36.6724H4.16986V39.018ZM0 26.6201H4.16986V16.5678H0V26.6201ZM0 6.51541H4.16986V4.16986H5.63428V0H1.48924C0.666754 0 0 0.666755 0 1.48924V6.51541ZM13.9244 0V4.16986H22.2144V0H13.9244ZM30.5045 0V4.16986H38.7946V0H30.5045ZM47.0847 0V4.16986H48.5491V6.51541H52.719V1.48924C52.719 0.666754 52.0522 0 51.2297 0H47.0847ZM52.719 16.5678H48.5491V26.6201H52.719V16.5678ZM52.719 36.6724H48.5491V39.018H47.0847V43.1878H51.2297C52.0522 43.1878 52.719 42.5211 52.719 41.6986V36.6724ZM38.7946 43.1878V39.018H30.5045V43.1878H38.7946ZM22.2144 43.1878V39.018H13.9244V43.1878H22.2144Z"
                                fill="white" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.16986 4.16986V13.5521H20.8493V4.16986H4.16986ZM1.48924 0C0.666754 0 0 0.666754 0 1.48924V16.2327C0 17.0552 0.666754 17.7219 1.48924 17.7219H23.5299C24.3524 17.7219 25.0192 17.0552 25.0192 16.2327V1.48924C25.0192 0.666754 24.3524 0 23.5299 0H1.48924Z"
                                fill="#6D7AF2" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M31.8701 29.3378V39.0179H48.5495V29.3378H31.8701ZM29.1894 25.168C28.3669 25.168 27.7002 25.8347 27.7002 26.6572V41.6985C27.7002 42.521 28.3669 43.1877 29.1894 43.1877H51.2301C52.0526 43.1877 52.7194 42.521 52.7194 41.6985V26.6572C52.7194 25.8347 52.0526 25.168 51.2301 25.168H29.1894Z"
                                fill="#6D7AF2" />
                            </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-7">
                        <router-link :to="`/${currentLang}/alternative`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-3')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="48" viewBox="0 0 56 48"
                            fill="none">
                            <path
                                d="M20.7004 37.3053C20.7004 43.0215 16.0664 47.6555 10.3502 47.6555C4.63394 47.6555 0 43.0215 0 37.3053C0 31.589 4.63394 26.9551 10.3502 26.9551C16.0664 26.9551 20.7004 31.589 20.7004 37.3053Z"
                                fill="white" />
                            <path
                                d="M55.6975 10.3502C55.6975 16.0664 51.0635 20.7004 45.3473 20.7004C39.631 20.7004 34.9971 16.0664 34.9971 10.3502C34.9971 4.63394 39.631 0 45.3473 0C51.0635 0 55.6975 4.63394 55.6975 10.3502Z"
                                fill="white" />
                            <path class="hover_dark" fill-rule="evenodd" clip-rule="evenodd"
                                d="M45.6179 8.35748C46.7596 8.50723 47.5637 9.55416 47.414 10.6959L44.9736 29.3009C44.8238 30.4426 43.7769 31.2467 42.6352 31.0969C41.4935 30.9472 40.6894 29.9003 40.8391 28.7586L42.6249 15.1443L11.6197 38.9589C10.7065 39.6603 9.39761 39.4886 8.6962 38.5754C7.99479 37.6622 8.16648 36.3533 9.07967 35.6519L40.0849 11.8373L26.4706 10.0516C25.3289 9.90181 24.5247 8.85488 24.6745 7.71318C24.8243 6.57149 25.8712 5.76736 27.0129 5.91711L45.6179 8.35748Z"
                                fill="#6D7AF2" />
                        </svg>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide id="slide-8">
                        <router-link :to="`/${currentLang}/engine`" class="myCard hover_blue">
                            <h5 v-html="$t('OtherSolutions.cards.card-4')"></h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="41" viewBox="0 0 60 41"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M20.217 4.16986C11.3544 4.16986 4.16986 11.3544 4.16986 20.217C4.16986 29.0796 11.3544 36.2642 20.217 36.2642C21.3685 36.2642 22.3019 37.1976 22.3019 38.3491C22.3019 39.5006 21.3685 40.434 20.217 40.434C9.05147 40.434 0 31.3826 0 20.217C0 9.05147 9.05147 0 20.217 0C31.3826 0 40.434 9.05147 40.434 20.217C40.434 21.3685 39.5006 22.3019 38.3491 22.3019C37.1976 22.3019 36.2642 21.3685 36.2642 20.217C36.2642 11.3544 29.0796 4.16986 20.217 4.16986Z"
                                fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M39.2039 36.2642C48.0665 36.2642 55.251 29.0797 55.251 20.2171C55.251 11.3545 48.0665 4.16991 39.2039 4.1699C38.0524 4.1699 37.119 3.23645 37.119 2.08497C37.119 0.9335 38.0524 4.39083e-05 39.2039 4.40089e-05C50.3694 4.49851e-05 59.4209 9.05151 59.4209 20.2171C59.4209 31.3826 50.3694 40.4341 39.2039 40.4341C28.0383 40.4341 18.9869 31.3826 18.9869 20.2171C18.9869 19.0656 19.9203 18.1321 21.0718 18.1321C22.2233 18.1321 23.1567 19.0656 23.1567 20.2171C23.1567 29.0797 30.3413 36.2642 39.2039 36.2642Z"
                                fill="white" />
                            <path class="hover_dark"
                                d="M26.7673 20.3121C26.7673 23.8773 23.8771 26.7675 20.3118 26.7675C16.7466 26.7675 13.8564 23.8773 13.8564 20.3121C13.8564 16.7469 16.7466 13.8567 20.3118 13.8567C23.8771 13.8567 26.7673 16.7469 26.7673 20.3121Z"
                                fill="#6D7AF2" />
                            <path class="hover_dark"
                                d="M44.8044 20.3121C44.8044 23.8773 41.9142 26.7675 38.349 26.7675C34.7837 26.7675 31.8936 23.8773 31.8936 20.3121C31.8936 16.7469 34.7837 13.8567 38.349 13.8567C41.9142 13.8567 44.8044 16.7469 44.8044 20.3121Z"
                                fill="#6D7AF2" />
                            </svg>
                        </router-link>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

    </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import { computed, watch } from 'vue';
import { useRoute} from 'vue-router';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';

export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const route = useRoute();
      const { locale } = useI18n();
  
      const currentLang = computed(() => route.params.lang || 'en');
  
      watch(
        () => route.params.lang,
        (newLang) => {
          locale.value = newLang;
        }
      );
  
      return {
        modules: [Autoplay],
        currentLang,
      };
    }
}
</script>

<style scoped>
section {
    padding: 73px 0 120px;
    background-color: #fff;
    color: #161616;
    overflow: hidden;
}

.h2 {
    margin-bottom: 15px;
}

.subtitle {
    margin-bottom: 41px;
}

.myCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: none;
}

.myCard img, .myCard svg {
    margin-left: auto;
}

.myCard h5 {
    color: #fff;
    font-family: "Days One", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.mySwiper {
    background-color: #fff;
}

.swiper_container {
    margin-right: calc(100% - 100vw);
}

.myCard {
    height: 163px;
}

@media (max-width:768px) {
    .myCard h5{
        font-size: 18px;
    }
    .h2 br {
        display: none;
    }
}
</style>