export default {
  "header": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "menu": {
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукты"])},
      "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решения"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])}
    }
  },
  "footer": {
    "menu": {
      "item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классический эквайринг"])},
      "item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRYPTO эквайринг"])},
      "item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативные шлюзы"])},
      "item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label для криптосервиса"])},
      "item-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные и подарочные карты"])},
      "item-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные методы"])}
    }
  },
  "home": {
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зона <span class='light_blue'>быстрых</span> платежей"])},
      "special-word-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["точных"])},
      "special-word-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["крипто"])},
      "special-word-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["безопасных"])},
      "special-word-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лёгких"])},
      "special-word-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["глобальных"])},
      "special-word-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["быстрых"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позвольте бизнесу расти, принимая все виды платежей по всему миру без ограничений"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])},
      "card1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классический <br> эквайринг"])},
      "card2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRYPTO <br> эквайринг"])},
      "card3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативные <br> шлюзы"])},
      "card4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label для <br> криптосервиса"])},
      "card5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные <br> и подарочные карты"])},
      "card6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные <br> методы"])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Увеличьте</span> доход <br> с помощью Pays.Zone"])},
      "list-item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надежный поставщик финансовых услуг"])},
      "list-item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гибкая настройка под каждого клиента"])},
      "list-item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Огромные возможности аналитики данных"])},
      "list-item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокая конверсия платежей"])},
      "list-item-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дружелюбный интерфейс для всех устройств"])}
    },
    "section3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Универсальные</span> <br> решения"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все платежные методы детально проработаны командой квалифицированных финансистов и закрывают большинство потребностей продавцов."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к решениям"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Для любых</span> <br> сфер бизнеса"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Международные платежи для онлайн индустрии с разным уровнем риска - криптосервисы, e-коммерс, онлайн гейминг, инфлюэнсеры, цифровые платформы и многие других."])}
    },
    "section5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Гибкое</span> <br> API"])},
      "description": {
        "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широкие возможности интеграции, множество плагинов для популярных CMS и сервисов."])},
        "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможность встраивания через webview и SDK."])},
        "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное сопровождение техническими специалистами и аккаунт менеджерами на всем пути интеграции."])}
      }
    },
    "section6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Легкое</span> <br> подключение"])},
      "description": {
        "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрый онбординг и рациональный KYB"])},
        "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное сопровождение с момента подачи заявки до завершения тестирования интеграции."])}
      }
    }
  },
  "easyConnect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начнем <br> подключение?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работать с нашей компанией легко и удобно, поскольку мы предлагаем гибкие условия сотрудничества и интуитивно понятные инструменты для ведения бизнеса."])},
    "items": {
      "item-1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставьте заявку"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш аккаунт менеджер свяжется с Вами в самое ближайшее время, внимательно выслушает Вас и предложит лучшие варианты сотрудничества."])}
      },
      "item-2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдите быстрый KYB"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Дайте нам немного времени и получите одобрение!</p> <span>Благодаря широкому спектру платежных методов мы можем найти решение буквально для каждого клиента.</span>"])}
      },
      "item-3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начните принимать платежи"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>После одобрения мерчант-аккаунта Вы сможете принимать платежи через несколько дней.</p> <span>Не медлите и 👇</span>"])}
      }
    },
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключайтесь"])}
  },
  "howItwork": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает?"])}
  },
  "OtherSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие <br> решения"])},
    "cards": {
      "card-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классический <br> эквайринг"])},
      "card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRYPTO <br> эквайринг"])},
      "card-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативные <br> шлюзы"])},
      "card-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label <br> для криптосервиса"])},
      "card-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные <br> и подарочные карты"])},
      "card-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные <br> методы"])}
    }
  },
  "crypto": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоэквайринг"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимайте оплату от клиентов в криптовалюте."])},
      "list": {
        "li-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без границ"])},
        "li-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой KYC / KYB"])},
        "li-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По всему миру и в любой валюте"])}
      }
    },
    "section-1-5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Универсальное решение для"])},
      "card-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-коммерс <br> бизнесов"])},
      "card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптокошельков <br> и криптосервисов"])},
      "card-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недвижимости <br>"])},
      "card-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диджатал <br> платформ"])},
      "card-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игровых <br> сервисов"])},
      "card-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телеграм-ботов"])},
      "card-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благотворительность"])},
      "card-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сферы услуг"])}
    },
    "section-2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачем вам криптоэквайринг?"])},
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчетный счет <br> в крипте"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Принимайте оплату также, как и на расчетный счет, только в криптовалюте. </p><p> Копите активы, конвертируйте или выводите.</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкие комиссии"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Индивидуальная комиссия <br> на все входящие платежи - от 0%.</p> <p> Специальные условия для проектов с оборотом более 100 000$ в месяц.</p>"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Весь мир - Ваш!"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоэквайринг - <br> реальная возможность принимать платежи от резидентов даже самых отдаленных стран!"])}
        },
        "card-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широкие возможности"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод средств 24/7 (даже в выходные!). <br> Возможность конвертации в 1 клик. Вывод на партнерские криптосервисы."])}
        },
        "card-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка 24/7"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть вопросы или возникла проблема? <br> Наша поддержка всегда на связи и в будни и праздники, как днем, так и ночью."])}
        }
      }
    },
    "section-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает?"])},
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание счета"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет на оплату создается вручную в личном кабинете мерчанта или автоматически при интеграции с сайтом"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата счета"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент оплачивает счет одним из доступных методов"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступление средств"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денежные средства мгновенно зачисляются на баланс мерчанта"])}
        }
      }
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что если у пользователя нет крипты?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый пользователь из разрешенных локаций имеет возможность купить криптовалюту в процессе платежа"])}
    },
    "scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheme"])},
    "scheme_mob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheme-mobile"])}
  },
  "alternative": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативные платежные методы"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимайте платежи на любых онлайн платформах в удобном формате."])}
    },
    "section-2": {
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h2h интеграция"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Интеграция процессинга в Ваш проект по легкому и понятному API.</p> <p>Средний срок интеграции - 3 дня</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные страницы"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенаправляйте пользователей на наши платежные страницы с возможностью брендирования"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телеграм-бот"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделаем под Ваш проект платежную платформу в виде брендированного телеграм-бота"])}
        }
      }
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преимущества"])},
      "benefit-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокая конверсия платежей"])},
      "benefit-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты <br> день-в-день"])},
      "benefit-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовые выплаты <br> на любые банки"])},
      "benefit-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без депозита <br> и комиссии за сетап"])},
      "benefit-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99,9% реального аптайма"])},
      "benefit-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без ограничений <br> по сферам бизнеса"])}
    },
    "forWho": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для кого подойдут данные решения?"])},
      "items": {
        "item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Криптосервисы</span> <span class='special'>(кошельки и обменники)</span>"])},
        "item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недвижимости"])},
        "item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Е-коммерс"])},
        "item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диджитал <br> платформы"])},
        "item-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телеграм-боты"])},
        "item-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благотворительность"])},
        "item-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн гейминг"])}
      },
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])}
    },
    "functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные функции"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Административная панель"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Мощная административная панель для управления финансами и другими данными.</p> <p>Полные отчеты, выгрузки в excel формате, настраиваемая аналитика, отслеживание балансов и многие другие необходимые функции.</p>"])}
    },
    "api": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API документация"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Простая и понятная документация по интеграции.</p> <p>Среднее время запуска проекта - 3 дня.</p> <p>Предусмотрены все описанные выше варианты интеграции.</p> <p>Поддержка на всех стадиях интеграции.</p>"])}
    },
    "withdrawal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод баланса в USDT"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Все платежи, совершенные в любой фиатной валюте, автоматически конвертируются в USDT.</p> <p>Вывод средств с баланса доступен 24 часа в сутки 7 дней в неделю и обрабатывается в тот же день.</p>"])}
    },
    "telegram": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телеграм уведомления"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный спектр уведомлений о каждом проводимом действии через специальные телеграмм-боты."])}
    }
  },
  "aquiring": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эквайринг"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='undertitle mb-3'>Pays.Zone предоставляет прямой платежный сервис для различных сфер бизнеса.</p>  <p class='undertitle mb-3'>Наш платежный шлюз дает возможность пользователям совершить прямую безопасную оплату с адаптивным риск-менеджментом.</p>"])}
    },
    "section-2": {
      "accordion": {
        "item-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web платежи"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимайте платежи на десктоп и мобильных сайтах"])}
        },
        "item-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDK платежи"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимайте платежи с банковских карт в мобильных приложениях"])}
        },
        "item-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекурентные платежи"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужно принимать платежи по подписке? Делайте это легко с модулем pays.zone по рекурентным платежам"])}
        },
        "item-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежная ссылка"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерируйте по API или вручную в административной панели специальную ссылку на безопасную оплату"])}
        },
        "item-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СБП"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проводите оплату Ваших услуг на российском рынке через наш шлюз СБП"])}
        }
      }
    },
    "forWho": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для кого подойдeт <br> данное решение?"])},
      "cards": {
        "card-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптосервисы <br> (кошельки и обменники)"])},
        "card-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Е-коммерс"])},
        "card-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диджитал <br> платформы"])},
        "card-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телеграм-боты"])},
        "card-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благотворительность"])},
        "card-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сферы услуг"])}
      }
    },
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["big"])},
    "imageMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mb_ru"])}
  },
  "engine": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label решение для криптосервиса"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запустите собственный криптокошелек, криптообменник или целый криптобанк с помощью движка криптосервиса от команды Pays.Zone"])},
      "button-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демо"])},
      "button-1-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://valutix.io/"])},
      "button-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])}
    },
    "section-2": {
      "cards": {
        "card-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500+ направлений <br> обмена"])},
        "card-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решения по <br> привлечению траффика"])},
        "card-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широкая <br> география для запуска"])},
        "card-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью <br> настраиваемое решение"])},
        "card-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернутая <br> аналитика и отчетность"])}
      }
    },
    "section-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вы получите?"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брендированная платформа"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптосервис запускается на Вашем домене, с Вашим дизайном."])}
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Административная панель"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С настройками сервиса, полной аналитикой, базой данных пользователей и транзакций"])}
    },
    "section-5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональный менеджер"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Который сопроводит на всех этапах подключения и будет оказывать полную поддержку."])}
    },
    "section-6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консультацию по работе"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class='mb-3'>Мы имеем колоссальный опыт в запуске криптосервисов.<p> <p>Поможем с подбором юрисдикции, разработкой и настройкой бизнес-процессов.</p>"])}
    },
    "section-8": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flag_map"])}
    },
    "section-9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигуратор возможностей"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете выбрать, какие функции будут доступны в Вашем сервисе, или заказать полноценный продукт со всеми функциями."])},
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация и верификация"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Пользователи проходят регистрацию и верификацию в сервисе.</p> <p>Для верификации может быть подключен сторонний сервис или ручная обработка.</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунты и баланс"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователям доступна функция хранения средств на аккаунтах, хранится история всех сделок."])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мгновенный обмен"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможность создания и обработки заявки без регистрации аккаунта. Подходит для большинства обменных пунктов."])}
        },
        "card-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета на оплату и платежная ссылка"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи могут создавать счета на оплату или пополнять балансы с помощью публично доступной платежной ссылки."])}
        },
        "card-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление платежными методами"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Вы можете добавлять или убирать, активировать или деактивировать любые платежные методы, актуальные в Вашей юрисдиксции.</p> <p>Устанавливайте собственные курсы валют и комиссии платежных методов.</p>"])}
        },
        "card-6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация криптокомпании"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При необходимости, мы поможем Вам пройти весь процесс по регистрации компании в юрисдикции, где разрешена деятельность криптовалютных компаний."])}
        }
      }
    },
    "section-10": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость <br> решения"])},
      "cards": {
        "card-1": {
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата сетапа"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единоразовая оплата за подключение решения"])}
        },
        "card-2": {
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое сопровождение"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация компании, лицензии и счет в банке"])}
        },
        "card-3": {
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия транзакций"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата за каждую транзакцию пользователя"])}
        }
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Стоимость лицензии варьируется от страны к стране"])}
    }
  },
  "cards": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='light_blue'>Виртуальные <br> и подарочные карты <span>"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откройте собственный бизнес по выпуску <br> виртуальных и подарочных карт через API Pays.Zone"])}
    },
    "section-2": {
      "cards": {
        "card-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выпуск виртуальных карт американских и европейских банков"])},
        "card-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выпуск подарочных карт более чем 100 сервисов"])},
        "card-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любой объем <br> эмиссии карт"])},
        "card-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0% комиссии за <br> платежи"])}
      }
    },
    "section-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О сервисе"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты без 3DS"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моментальная оплата картой без смс и пуш уведомлений"])}
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка к кошелькам"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты можно привязать к Apple Pay, Google Pay и Samsung Pay"])}
    },
    "section-6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все под рукой"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты карты, остаток баланса и история оплат отображаются в личном кабинете"])}
    },
    "section-7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мгновенный выпуск"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сразу после оплаты - карта с балансом появляется в личном кабинете"])}
    },
    "section-8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широкие возможности"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи могут оплатить карту с помощью широкого спектра платежных систем - от фиата до крипты"])}
    },
    "section-9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для чего подходят?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для покупок в онлайн и оффлайн магазинах по всему миру"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["И 50+ сервисов"])}
    }
  },
  "about": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О компании <br><span class='light_blue'>Pays.Zone</span>"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2>Наша миссия</h2> <p class='m-0 text_container'>дать возможность проводить удобные платежи по всему миру без ограничений</p>"])}
    },
    "section-2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему выбирают нас?"])},
      "years": {
        "year-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Опыт работы в fintech более 7 лет. </p>"])},
        "year-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>В нашей экосистеме более 10 собственных инструментов, упрощающих работу с фиатом и криптовалютой</p>"])},
        "year-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Примняем современные технологии на основе искуственного интеллекта</p>"])},
        "year-description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Постоянно оптимизируем и повышаем конверсию трансграничных платежей</p>"])},
        "year-description-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Активно внедряем альтернативные платежные методы и криптовалютные расчеты</p>"])},
        "year-description-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Предлагаем гибкое и прозрачное ценообразование</p>"])}
      }
    },
    "section-4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payz.Zone в цифрах:"])},
      "items": {
        "item-1": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 транзакции"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обрабатываются каждую секунду"])}
        },
        "item-2": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 валют"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрабатывает наш процессинг"])}
        },
        "item-3": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["73 сотрудника"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневно трудится над экосистемой"])}
        },
        "item-4": {
          "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миллионы пользователей"])},
          "span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["используют наши сервисы для проведения оплаты"])}
        }
      }
    },
    "section-6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукты Pays.Zone"])},
      "cards": {
        "card-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классический эквайринг"])},
        "card-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRYPTO эквайринг"])},
        "card-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативные шлюзы"])},
        "card-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label для криптосервиса"])},
        "card-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные и подарочные карты"])},
        "card-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные методы"])}
      },
      "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы работаем над:"])},
      "list": {
        "list-item-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решениями по стейкингу и майнингу криптовалют"])},
        "list-item-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приемом платежей в Латинской Америке"])},
        "list-item-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приемом платежей в Азии"])},
        "list-item-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улучшением сервиса текущих решений"])}
      }
    }
  },
  "ourSolution": {
    "section-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решения от <br> <span class='light_blue'>Pays.Zone</span>"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Наша команда разрабатывает стабильные, безопасные и высококонверсионные платежные решения.</p> <p>Принимая платежи с Pays.Zone, Ваш бизнес будет работать 24/7</p> <p>Вы сможете без проблем расширить географию своего присутствия и с легкостью открыть новые направления коммерческой деятельности.</p> <p>Работая с нами, Вы можете быть уверены, что приобретаете ПО конечного разработчика.</p>"])}
    },
    "section-4": {
      "cards": {
        "card-1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классический <br> эквайринг"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Pays.Zone предоставляет прямой платежный сервис для различных сфер бизнеса.</p> <p>Наш платежный шлюз  дает возможность пользователям совершить прямую безопасную оплату с адаптивным риск-менеджментом.</p>"])}
        },
        "card-2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRYPTO <br>  эквайринг"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Надежный и стабильный шлюз по приему платежей в криптовалюте.</p> <p>Интеграция с любой фиатной валютой. Принимайте платежи без границ и дополнительных проверок.</p>"])}
        },
        "card-3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативные <br>  методы"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Принимайте платежи на любых онлайн платформах в удобном формате. Подходит для любых сфер бизнеса.</p>"])}
        },
        "card-4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White-label решение <br> для криптосервиса"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Запустите собственный криптокошелек, криптообменник или целый криптобанк с помощью движка криптосервиса от команды Pays.Zone</p>"])}
        },
        "card-5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные <br>  и подарочные карты"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Откройте собственный бизнес по выпуску виртуальных и подарочных карт через API Pays.Zone</p>"])}
        },
        "card-6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужно разработать особый платежный шлюз?"])}
        }
      },
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработать"])}
    }
  },
  "modal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить письмо"])},
    "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше имя"])},
    "mail-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "text-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
    "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимая на кнопку “Отправить”, вы соглашаетесь с правилами сервиса и политикой конфиденциальности."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо отправлено"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])},
    "error-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при отправке сообщения"])}
  }
}