<template>
    <section id="five">
        <div class="container">
            <img src="/static/img/alternative-page/Union.svg" class="mx-auto mb_30 d-block d-sm-none">
            <h5 class="h5" v-html="$t('alternative.withdrawal.title')"></h5>
            <div class="text" v-html="$t('alternative.withdrawal.description')">
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    padding: 60px 0 138px;
    background-color: #363957;
    border-radius: 60px 60px 0 0;
    position: relative;
}

.h2 {
    margin-bottom: 60px;
}
.h5 {
    margin-bottom: 15px;
    color: #FFF;
    font-family: "Days One", sans-serif;
    font-size: 24px;
}
.text {
    font-size: 24px;
    max-width: 570px;
}
@media(max-width: 768px) {
    section {
      border-radius: 20px 20px 0 0 !important;
      padding: 50px 0;
    }

}
@media(max-width: 500px) {
    .h5 {
        font-size: 18px;
    }
    .text {
        font-size: 16px;
    }
}
</style>