<template>
    <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-sm">
        <div class="modal-content bg_blue">
          <div class="modal-header border-0">
            <h1 class="modal-title h2 m-0" id="supportModalLabel">{{ successText ? successText : $t('modal.title')}}</h1>
            <button type="button" class="btn-close shadow-none btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="successTextreset"></button>
          </div>
          <div class="modal-body">
            <div :class="successText ? 'opacity-0' : ''">
              <div class="mb-3 position-relative">
                <input type="text" class="form-control form-control-lg fs-5 border-0 shadow-none" id="name" :placeholder="$t('modal.name-placeholder')" v-model="name">
              </div>
  
              <div class="mb-3 position-relative">
                <input type="email" class="form-control form-control-lg fs-5 border-0 shadow-none" id="email_input" aria-describedby="emailHelp" placeholder="Email" v-model="email">
              </div>
              <div class="mb-3">
                <textarea class="form-control form-control-lg border-0 shadow-none" id="textarea_input" rows="4" :placeholder="$t('modal.text-placeholder')" v-model="message"></textarea>
              </div>
              <div class="mb-3" v-if="errorText">
                <span class="fs-6 text-center mx-auto text-danger d-block">{{errorText}}</span>
              </div>
              <button class="myButton mw-100" @click="send">{{$t('modal.button')}}</button>
              <div class="form-text mt-3 text-white text-center">
                {{ $t('modal.subtext') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        name: '',
        email: '',
        message: '',
        successText: '',
        errorText: '',
      };
    },
    methods: {
      send() {
        let data = {
          name: this.name,
          email: this.email,
          message: this.message,
        };
        if (this.name && this.email) {
          axios
            .post('/send_email.php', data, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then((response) => {
              if (response.status === 200) {
                this.successText = this.$t('modal.success');
                this.errorText = '';
              } else {
                this.errorText = this.$t('modal.error-2');
                this.successText = '';
              }
            })
            .catch((error) => {
              this.errorText = this.$t('modal.error-2');
              this.successText = '';
            });
        } else {
          this.errorText = this.$t('modal.error');
          this.successText = '';
        }
      },
      successTextreset() {
        this.successText = '';
        this.message = '';
        this.email = '';
        this.name = '';
        this.errorText = ''
      },
      setupModalEventListeners() {
        const modal = document.getElementById('supportModal');
        modal.addEventListener('hidden.bs.modal', this.successTextreset);
      },
      removeModalEventListeners() {
        const modal = document.getElementById('supportModal');
        modal.removeEventListener('hidden.bs.modal', this.successTextreset);
      }
    },
    mounted() {
    this.setupModalEventListeners();
    },
    beforeUnmount() {
      this.removeModalEventListeners();
    }
  };
  </script>

<style scoped>
    h1 {
        text-transform: none;
        font-family: "Days One", sans-serif;
        font-size: 30.5px;
    }
    .modal-dialog {
        max-width: 560px;
    }
    .modal-content {
        padding: 25px 40px;
    }

    #email_input, #name {
        bottom: -10px;
        font-size: 9px;
    }
    .bg_blue {
        background: #363957;
    }

    .myButton {
        height: 51px;
        max-width: -moz-fit-content;
        max-width: fit-content;
        width: 100%;
        padding: 12px 44px;
        margin: 0;
        color: #fff;
        border: none;
        border-radius: 5px;
        background: #6D7AF2;
        font-size: 22px;
    }

    @media (max-width: 500px) {
        .modal-content {
            padding: 0;
        }
    }
</style>