<template>
    <section id="two">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h2 class="h2">
                        {{ $t('crypto.section-3.title') }}
                    </h2>
                </div>
            </div>

            <div class="grid-container">
                <div class="grid-item card-dark">
                    <div class="text">
                        <h6>1. {{ $t('crypto.section-3.cards.card-1.title') }}</h6>
                        <p>{{ $t('crypto.section-3.cards.card-1.text') }}</p>
                    </div>
                    <img src="/static/img/crypto-page/section-3/3.svg" class="card-img">
                </div>
                <div class="grid-item card-dark">
                    <div class="text">
                        <h6>2. {{ $t('crypto.section-3.cards.card-2.title') }}</h6>
                        <p>{{ $t('crypto.section-3.cards.card-2.text') }}</p>
                    </div>
                    <img src="/static/img/crypto-page/section-3/2.svg" class="card-img">
                </div>
                <div class="grid-item card-dark">
                    <div class="text">
                        <h6>3. {{ $t('crypto.section-3.cards.card-3.title') }}</h6>
                        <p>{{ $t('crypto.section-3.cards.card-3.text') }}</p>
                    </div>
                    <img src="/static/img/crypto-page/section-3/1.svg" class="card-img">
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
section {
    padding: 98px 0 139px;
    background-color: #fff;
    color: #161616;
}

.h2 {
    margin-bottom: 69px;
}

.list_item {
    display: flex;
    align-items: center;
    gap: 11px;
    max-width: 348px;
}

.list_items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #1E1D1E;
    font-size: 25px;
    font-weight: 500;
    line-height: 87.2%;
    /* 21.8px */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* Три колонки в первом ряду */
    grid-template-rows: auto auto;
    /* Два ряда */
    grid-gap: 30px;
    /* Отступы между блоками */
    align-items: stretch;
}

.card-dark {
    display: flex;
    flex-direction: column;
    color: #fff;
    border-radius: 20px;
    background: #363957;
    overflow: hidden;
}

.card-dark img {
    border-radius: 20px;
    margin-top: auto;
    width: 102%;
}

.card-dark .text {
    padding: 20px;
}

.card-dark h6 {
    font-family: "Days One", sans-serif;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: normal;
}

.grid-item {
    grid-column: span 2;
    /* Занимает две колонки */
}

.grid-item p {
    margin: 0;
}

.grid-item:nth-child(2) p {
    max-width: 220px;
}

.grid-item:nth-child(3) p {
    max-width: 250px;
}

@media(max-width: 768px) {
    .grid-item {
        grid-column: span 6;
    }
    .grid-item p {
        max-width: unset !important;
    }
    section {
        padding: 50px 0 50px;
    }
    .card-img {
        max-width: 500px;
        margin: 0 auto;
        padding: 50px;
    }
}

@media(max-width: 500px) {
    section {
        padding: 50px 0 80px;
    }
    .h2 {
        margin-bottom: 30px;
    }
    .card-img {
        max-width: unset;
        padding: 50px 0 0;
        height: 102%;
    }
    .card-dark {
        gap: 20px;
    }
}
</style>