<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-7">
                    <h2 class="h2" v-html="$t('engine.section-9.title')"></h2>
                    <div class="text">
                        <p>{{$t('engine.section-9.description')}}</p>
                    </div>
                </div>
                <div class="cards">
                    <div class="row myGap">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="myCard">
                                <div class="card_header d-flex align-items-center gap-3">
                                    <img src="/static/img/engine-page/basic/2.svg">
                                    <h6 v-html="$t('engine.section-9.cards.card-1.title')"></h6>
                                </div>
                                <div class="card_body" v-html="$t('engine.section-9.cards.card-1.description')"></div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="myCard">
                                <div class="card_header d-flex align-items-center gap-3">
                                    <img src="/static/img/engine-page/basic/6.svg">
                                    <h6 v-html="$t('engine.section-9.cards.card-2.title')"></h6>
                                </div>
                                <div class="card_body" v-html="$t('engine.section-9.cards.card-2.description')">
                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="myCard">
                                <div class="card_header d-flex align-items-center gap-3">
                                    <img src="/static/img/engine-page/basic/3.svg">
                                    <h6 v-html="$t('engine.section-9.cards.card-3.title')"></h6>
                                </div>
                                <div class="card_body" v-html="$t('engine.section-9.cards.card-3.description')">
                                   
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="myCard">
                                <div class="card_header d-flex align-items-center gap-3">
                                    <img src="/static/img/engine-page/basic/1.svg">
                                    <h6 v-html="$t('engine.section-9.cards.card-4.title')"></h6>
                                </div>
                                <div class="card_body" v-html="$t('engine.section-9.cards.card-4.description')">
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="myCard">
                                <div class="card_header d-flex align-items-center gap-3">
                                    <img src="/static/img/engine-page/basic/4.svg">
                                    <h6 v-html="$t('engine.section-9.cards.card-5.title')"></h6>
                                </div>
                                <div class="card_body" v-html="$t('engine.section-9.cards.card-5.description')">
                                   
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="myCard">
                                <div class="card_header d-flex align-items-center gap-3">
                                    <img src="/static/img/engine-page/basic/5.svg">
                                    <h6 v-html="$t('engine.section-9.cards.card-6.title')"></h6>
                                </div>
                                <div class="card_body" v-html="$t('engine.section-9.cards.card-6.description')">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    color: #161616;
    background: #fff;
    border-radius: 0 !important;
    padding: 50px 0 133px;
}

.h2 {
    font-family: "Days One", sans-serif;
    font-size: 40px;
    margin-bottom: 23px;
}

.text {
    margin-bottom: 55px;
}

.myCard {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    color: #fff;
    border-radius: 20px;
    overflow: hidden;
}

.myCard h6 {
    margin: 0;
}

.myGap {
    row-gap: 30px;
    align-content: stretch;
}

.card_header {
    background-color: #161616;
    font-size: 20px;
    font-family: "Days One", sans-serif;
    padding: 0 20px;
}

.card_body {
    background-color: #363957;
    font-size: 18px;
    padding: 10px 25px;
    font-family: "Helvetica Neue", sans-serif;
    height: 100%;
}

@media (max-width: 768px) {
    .section {
        padding: 50px 0;
        border-radius: 20px 20px 0 0;
    }
}

@media (max-width: 500px) {
    .h2 {
        font-size: 25px;
        line-height: 87.2%;
        margin-bottom: 10px;
    }

    .text {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .myCard {
        height: 285px;
    }
}
</style>