<template>
    <section>
        <div class="container">
            <div class="scheme_container">
                   <img class="d-none d-sm-block" :src="`/static/img/crypto-page/section-5/${$t('crypto.scheme')}.svg`" alt="схема">
                   <img class="mx-auto d-block d-sm-none" :src="`/static/img/crypto-page/section-5/${$t('crypto.scheme_mob')}.svg`" alt="схема">
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section {
    padding: 164px 0 164px;
    background-color: #fff;
    border-radius: 60px 60px 0 0;
}

.scheme_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scheme_container img {
    max-width: 100%;
}

@media(max-width:500px) {
    section {
        padding: 50px 0;
        border-radius: 20px 20px 0 0;
    }
}

</style>