  <template>

          <Section1/>
          <Section2/>
          <Section3/>
          <Section4/>
          <Section5/>
          <EasyConnect/>
          <Other/>
          <Footer/>
  
  </template>
  
  <script>
  // В вашем <script> теге
    import Section1 from "@/components/crypto-page/Section1.vue";
    import Section2 from "@/components/crypto-page/Section2.vue";
    import Section3 from "@/components/crypto-page/Section3.vue";
    import Section4 from "@/components/crypto-page/Section4.vue";
    import Section5 from "@/components/crypto-page/Section5.vue";
    import EasyConnect from "@/components/EasyConnect.vue";
    import Other from "@/components/Other.vue";
    import Footer from "@/components/Footer.vue";
  
  export default {
    name: 'CryptoView',
      components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Other,
        EasyConnect,
        Footer,
      },
    };
  </script>
  
<style scoped>

#easy_connect_section {
  background: #fff !important;
}

</style>
  