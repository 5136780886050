<template>
    <section id="easy_connect_section">
        <div class="brs">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <h2 class="h2" v-html="$t('easyConnect.title')"></h2>
                        <p class="subtitle">
                           {{ $t('easyConnect.description') }}
                        </p>
                    </div>
                </div>
                <ul class="easy_connect_list">
                    <li>
                        <h6>{{ $t('easyConnect.items.item-1.title') }}</h6>
                        <span>
                            {{ $t('easyConnect.items.item-1.description') }}
                        </span>
                    </li>
                    <li>
                        <h6>{{ $t('easyConnect.items.item-2.title') }}</h6>
                        <div v-html="$t('easyConnect.items.item-2.description')"></div>
                    </li>
                    <li>
                        <h6>{{ $t('easyConnect.items.item-3.title') }}</h6>
                        <div v-html="$t('easyConnect.items.item-3.description')"></div>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#supportModal" class="myButton mob_btn d-flex mt-4">{{ $t('easyConnect.link') }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<style scoped>
    section {
        padding: 0 !important;
        border-radius: 0 !important; 
    }
    .brs {
        padding: 73px 0 74px;
        background-color: #fff;
        color: #1E1D1E;
        border-radius: 60px 60px 0 0;
    }

    .h2 {
        margin-bottom: 15px;
    }

    .subtitle {
        margin-bottom: 119px;
    }

    .easy_connect_list {
        color: #1E1D1E;
        font-family: "Helvetica Neue", sans-serif;
        font-style: normal;
        line-height: normal;
        list-style: none;
    }

    .easy_connect_list li h6 {
        position: relative;
    }

    .easy_connect_list li h6:before {
        content: '';
        width: 48px;
        height: 48px;
        background: #363957;
        position: absolute;
        top: 0;
        left: -70px;
        border-radius: 50%;
    }

    .easy_connect_list li {
        max-width: 470px;
    }

    .easy_connect_list li::marker {
        margin-top: 30px;
        margin-right: 20px;
        display: block;
    }

    .easy_connect_list h6 {
        font-size: 25px;
        font-weight: 500;
    }

    .easy_connect_list p, .easy_connect_list span {
        font-size: 20px;
        font-weight: 400;
    }

    .easy_connect_list li:first-child {
        margin-bottom: 145px;
    }

    .easy_connect_list li:nth-child(2) {
        margin-bottom: 96px;
        margin-left: auto;
    }

    .easy_connect_list li:first-child, .easy_connect_list li:last-child {
        margin-left: 148px;
    }

    .easy_connect_list {
        background: no-repeat url(/public/static/img/easy_bg.svg);
    }

    @media(max-width: 1118px) {
        .easy_connect_list li:first-child, .easy_connect_list li:last-child {
            margin-left: unset;
        }
        .easy_connect_list li:nth-child(2) {
            margin-left: unset;
        }
        .easy_connect_list li {
            margin-bottom: 50px !important;
        }
        .subtitle {
            margin-bottom: 50px;
        }
        .easy_connect_list li:last-child {
            margin-bottom: 0 !important;
        }
        .easy_connect_list {
            background: unset;
        }

        .easy_connect_list a {
            display: none;
        }

        .easy_connect_list li h6:before {
            content: '';
            width: 26px;
            height: 26px;
            background: #363957;
            position: absolute;
            top: 0;
            left: -30px;
            border-radius: 50%;
        }

        .easy_connect_list li {
           max-width: unset;
        }
    }

    @media(max-width: 768px) {
        .brs {
            border-radius: 20px 20px 0 0;
        }
    }

    @media(max-width: 500px) {
        .brs {
            padding: 30px 0 50px;
        }
        .subtitle {
            margin-bottom: 32px;
        }
        .easy_connect_list {
            margin-bottom: 50px;
        }
        .easy_connect_list h6 {
            color: #1E1D1E;
            font-family: "Helvetica Neue";
            font-size: 18px;
            font-weight: bold;
        }
        .easy_connect_list li {
            margin-bottom: 22px !important;
        }
        .easy_connect_list span, .easy_connect_list p {
            font-size: 16px;
        }
        .mob_btn {
            width: 100%;
            max-width: unset;
        }
    }

</style>