<template>
    <section>
        <div class="brs">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <img src="/static/img/main-page/s3.svg">
                    </div>
                    <div class="col-md-5 ms-auto d-flex flex-column justify-content-center">
                        <h2 class="h2" v-html="$t('home.section3.title')"></h2>
                        <p class="subtitle">
                            {{ $t('home.section3.description') }}
                        </p>
                        <router-link class="myButton" :to="`/${currentLang}/our-solution`">{{ $t('home.section3.link') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
  import { computed, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
export default {
    setup() {
      const route = useRoute();
      const { locale } = useI18n();
  
      const lang = ['en', 'ru'];
      const currentLang = computed(() => route.params.lang || 'en');
  
      watch(
        () => route.params.lang,
        (newLang) => {
          locale.value = newLang;
        }
      );
  
      return {
        currentLang,
      };
    }
}
</script>

<style scoped>
section {
    background: #fff;
    padding: 0 !important;
    border-radius: 0 !important;
}

.brs {
    padding: 70px 0 50px;
    background-color: #363957;
    border-radius: 60px 60px 0 0;
}

.h2 {
    margin-bottom: 15px;
}

.subtitle {
    margin-bottom: 41px;
}

img {
    width: 100%;
    max-width: fit-content;
}

@media(max-width: 768px) {
    .brs {
        padding: 20px 0 60px;
        border-radius: 20px 20px 0 0;

    }
    img {
        margin-bottom: 20px;
    }
}

</style>