<template>
  <section>
        <div class="brs">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <h2 class="h2" v-html="$t('home.section2.title')"></h2>
                        <img src="/static/img/main-page/s2.svg" class="d-block d-sm-none w-100">
                        <ul class="list_items">
                            <li class="list_item">
                                <img src="/static/img/main-page/section2-list5.svg">
                                <span>{{ $t('home.section2.list-item-1') }}</span>
                            </li>
                            <li class="list_item">
                                <img src="/static/img/main-page/section2-list2.svg">
                                <span>{{ $t('home.section2.list-item-2') }}</span>
                            </li>
                            <li class="list_item">
                                <img src="/static/img/main-page/section2-list3.svg">
                                <span>{{ $t('home.section2.list-item-3') }}</span>
                            </li>
                            <li class="list_item">
                                <img src="/static/img/main-page/section2-list1.svg">
                                <span>{{ $t('home.section2.list-item-4') }}</span>
                            </li>
                            <li class="list_item">
                                <img src="/static/img/main-page/section2-list4.svg">
                                <span>{{ $t('home.section2.list-item-5') }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="d-none d-sm-flex col-sm-6 align-items-center">
                        <img src="/static/img/main-page/s2.svg" class="w-100">
                    </div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
    section {
        background: linear-gradient(92deg, #000 3.21%, #201F20 99.75%);
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .brs {
        padding: 96px 0 0;
        background-color: #fff;
        color: #161616;
        border-radius: 60px 60px 0 0;
    }
    .h2 {
        margin-bottom: 69px;
    }
    .list_item {
        display: flex;
        align-items: center;
        gap: 11px;
        max-width: 372px;
    }
    .list_items {
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: #1E1D1E;
        font-size: 25px;
        font-weight: 500;
        line-height: 87.2%; /* 21.8px */
    }

    @media(max-width: 1024px) {
        .brs {
            padding: 30px 0 80px;
        }
    }

    @media(max-width: 768px) {
        .brs {
            border-radius: 20px 20px 0 0;
        }
    }

    @media(max-width: 500px) {

        .list_items {
            color: #1E1D1E;
            font-family: "Helvetica Neue";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 87.2%; /* 15.696px */
            font-weight: bold;
        }
        .list_items .list_item img {
            width: 56px;
            height: 56px;
        }
    }
</style>