  <template>

  <Section1/>
  <Section4/>
  <EasyConnect/>

  <Footer/>
  
  </template>
  
  <script>
    import Section1 from "@/components/our-solution-page/Section1.vue";
    import Section4 from "@/components/our-solution-page/Section4.vue";
    import EasyConnect from "@/components/EasyConnect.vue";
    import Footer from "@/components/Footer.vue";
  
  export default {
    name: 'AboutView',
      components: {
        Section1,
        Section4,
        EasyConnect,
        Footer,
      },
    };
  </script>
  
<style scoped>

@media(max-width: 768px) {
  section {
    border-radius: 20px 20px 0 0;
    padding: 50px 0;
  }
  .myBrs {
    border-radius: 20px 20px 0 0 !important;
    padding: 50px 0;
  }
  #easy_connect_section {
    border-radius: 20px 20px 0 0;
  }
}
</style>
  