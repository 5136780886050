<template>
    <header class="bg-transparent">
      <nav class="navbar navbar-expand-lg navbar-dark shadow-none" data-bs-theme="dark">
        <div class="container">
          <div class="d-flex w-100 justify-content-between align-items-center py_21 gap-5">
            <div class="logo">
              <router-link :to="`/${currentLang}`" class="navbar-brand"><img src="/static/img/logo_payszone.svg" alt="logo"></router-link>
            </div>
            <button class="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="rounded-4 rounded-end offcanvas offcanvas-end text-bg-dark d-flex flex-lg-row justify-content-between align-items-center gap-2" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
              <div class="offcanvas-header justify-content-between w-100">
                <router-link exact :to="`/${currentLang}`" class="navbar-brand">
                  <img width="60" height="25" src="/static/img/logo_payszone.svg" alt="logo">
                </router-link>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle fs-6" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ currentLang.toUpperCase() }}</a>
                  <ul class="dropdown-menu dropdown-menu-dark border-0 shadow bg_dark text-white p-2 border-0">
                    <li class="dropdown-item" v-for="lang in filteredLangs" :key="lang" @click="changeLanguage(lang)">{{ lang.toUpperCase() }}</li>
                  </ul>
                </li>
                <button type="button" class="btn-close btn-close-white shadow-none" id="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body d-flex flex-column flex-lg-row justify-content-between w-100 gap-4">
                <ul class="top_menu rounded-pill fs-6 bg_dark navbar-nav order-1">
                  <li><router-link @click="closeOffcanvas" :to="`/${currentLang}/our-solution`">
                    {{ $t('header.menu.products') }}
                  </router-link></li>
                  <li>
                    <router-link @click="closeOffcanvas" :to="`/${currentLang}/about`">
                      {{ $t('header.menu.company') }}
                    </router-link>
                  </li>
                  <li class="dropdown">
                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {{ $t('header.menu.solutions') }}
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark border-0 shadow">
                      <li><router-link  @click="closeOffcanvas" class="dropdown-item" :to="`/${currentLang}/aquiring`">{{ $t('footer.menu.item-1') }}</router-link></li>
                      <li><router-link  @click="closeOffcanvas" class="dropdown-item" :to="`/${currentLang}/crypto`">{{ $t('footer.menu.item-2') }}</router-link></li>
                      <li><router-link  @click="closeOffcanvas" class="dropdown-item" :to="`/${currentLang}/alternative`">{{ $t('footer.menu.item-3') }}</router-link></li>
                      <li><router-link  @click="closeOffcanvas" class="dropdown-item" :to="`/${currentLang}/engine`">{{ $t('footer.menu.item-4') }}</router-link></li>
                      <li><router-link  @click="closeOffcanvas" class="dropdown-item" :to="`/${currentLang}/cards`">{{ $t('footer.menu.item-5') }}</router-link></li>
                      <li><router-link  @click="closeOffcanvas" class="dropdown-item" :to="`/${currentLang}/our-solution`">{{ $t('footer.menu.item-6') }}</router-link></li>
                    </ul>
                  </li>
                </ul>
                <!-- <div @click="toggleLogin" class="login rounded-pill p-2 cursor-pointer fs-6 bg_dark order-3 order-lg-2" :class="isLogin ? 'bg_blue' : ''">
                  <img src="/static/img/login.svg">
                  <span class="login_span">{{ $t('header.login') }}</span>
                </div> -->
                <div class="header_social order-2 order-lg-3 mb-auto mb-lg-0">
                  <a target="_blank" href="https://t.me/pays_zone">
                    <img class="black-icon" width="35" height="35" src="/static/img/ic_baseline-telegram.svg">
                    <img class="white-icon" width="35" height="35" src="/static/img/ic_baseline-telegram-white.svg">
                  </a>
                  <a target="_blank" href="https://www.instagram.com/pays.zone">
                    <img class="black-icon" width="35" height="35" src="/static/img/ri_instagram-fill.svg">
                    <img class="white-icon" width="35" height="35" src="/static/img/ri_instagram-fill-white.svg">
                  </a>
                  <li class="nav-item dropdown d-none d-lg-block">
                    <a class="nav-link dropdown-toggle fs-6" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ currentLang.toUpperCase() }}</a>
                    <ul class="dropdown-menu dropdown-menu-dark border-0 shadow bg_dark text-white border-0">
                      <li class="dropdown-item" v-for="lang in filteredLangs" :key="lang" @click="changeLanguage(lang)">{{ lang.toUpperCase() }}</li>
                    </ul>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n';
  import { computed, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  
  export default {
    methods: {
      closeOffcanvas() {
            let myOffcanvas = document.getElementById('offcanvasDarkNavbar');
            if(myOffcanvas) {
                let bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
                bsOffcanvas.hide();
            }
        },
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const { locale } = useI18n();
  
      const lang = ['en', 'ru'];
      const isLogin = ref(false);
      const currentLang = computed(() => route.params.lang || 'en');
      const filteredLangs = computed(() => lang.filter(l => l !== currentLang.value));
  
      const toggleLogin = () => {
        isLogin.value = !isLogin.value;
      };
  
      const changeLanguage = (newLang) => {
        locale.value = newLang;
        router.push({ params: { lang: newLang } });
      };
  
      watch(
        () => route.params.lang,
        (newLang) => {
          locale.value = newLang;
        }
      );
  
      return {
        isLogin,
        currentLang,
        filteredLangs,
        toggleLogin,
        changeLanguage
      };
    }
  };
  </script>

<style scoped>
header {
    margin-bottom: 58px;
}

.top_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 540px;
    padding: 17px 73px;
}

.login {
    width: 143px;
    display: flex;
    align-items: center;
    gap: 19px;
    cursor: pointer;
    user-select: none
}

.header_social {
    display: flex;
    align-items: center;
    gap: 30px;
}

.dropdown-menu-dark {
  background: #363957;
}

.dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.bg_blue {
    background-color: #6D7AF2;
    transition: .2s;
}

.white-icon {
    display: none;
}

.header_social a:hover .black-icon {
    display: none;
}

.header_social a:hover .white-icon {
    display: block;
}

.offcanvas-header .btn-close {
    padding: 0 !important;
    margin: 0 !important;
    width: 14px;
    height: 14px;
}

.dropdown-menu {
    min-width: unset !important;
    width: fit-content;
    left: -10px !important;
}

@media (max-width: 991px) {
    #offcanvasDarkNavbar {
        background-color: #363957 !important;
    }

    .top_menu {
        background: transparent !important;
        padding: 0;
        align-items: start;
        gap: 20px;
        font-size: 18px !important;
    }

    .offcanvas-header {
        padding: 20px 30px 20px 20px;
    }

    .login {
        width: 100%;
        background-color: #fff;
        color: #161616;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

    }

    .login img {
        margin-right: auto;
    }

    .login_span {
        position: absolute;
    }
}

@media(max-width: 500px) {
    header {
        margin-bottom: 31px;
    }
}
</style>